import { useIsAndroid } from '@/hooks';
import { Stack, Typography } from '@cyberlab/uikit';
import { useState } from 'react';
import Content from './Content';
import { Tab, TabType, Tabs } from './Tab';

function InstallPage() {
  const isAndroid = useIsAndroid();
  const [currentTab, setCurrentTab] = useState<TabType>(isAndroid ? 'android' : 'ios');
  return (
    <Stack
      gap={3}
      width='100vw'
      height='100vh'
      alignItems='center'
      justifyContent='flex-start'
      pt={'25vh'}
      sx={{
        backgroundImage: 'url(/assets/bg/login-bg.png)',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Typography fontSize='28px !important' fontWeight={700} lineHeight='34px !important'>
        Install Link3 Web App
      </Typography>

      <Tabs>
        <Tab onClick={() => setCurrentTab('ios')} isActive={currentTab === 'ios'}>
          iOS
        </Tab>
        <Tab onClick={() => setCurrentTab('android')} isActive={currentTab === 'android'}>
          Android
        </Tab>
      </Tabs>

      <Content type={currentTab} />
    </Stack>
  );
}

export default InstallPage;
