import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  TGetENSResult,
  TGetOptionsResult,
  TLinkPreviewResult,
  TPoapInfo,
  TW3stMetaData,
  TokenInfo,
  TokenPreviewResult,
} from './responesTypes';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.link3.to/api/',
    // baseUrl: 'http://localhost:3000/api/',
  }),
  reducerPath: 'thirdPartyApi',
  endpoints: (builder) => {
    return {
      getTokenLogo: builder.query<TokenInfo, string>({
        query: (symbol: string) => `token/tokenInfo?symbol=${symbol}`,
      }),
      getTokenPrice: builder.query({
        query: (symbol: string) => `token/tokenPrice?id=${symbol}`,
      }),
      getTokenPreview: builder.query<TokenPreviewResult, string>({
        query: (id: string) => `previewToken?id=${id}`,
      }),
      getLinkPreview: builder.query<TLinkPreviewResult, string>({
        query: (url: string) => `linkPreview?url=${url}`,
      }),

      retriveAllNFTByAddress: builder.query({
        query: (address: string) => `retriveAllNFTByAddress?address=${address}`,
      }),
      getPoapInfoByEventID: builder.query<TPoapInfo, string>({
        query: (eventID: string) => ({
          url: `poapAndCredentials/getPoapInfoByEventId?eventId=${eventID}`,
          method: 'GET',
        }),
      }),
      getDiscordAccessToken: builder.query({
        query: (code: string) => ({
          url: `getDiscordAccessToken?code=${code}`,
          method: 'GET',
        }),
      }),
      getDiscordNameById: builder.query<
        {
          data: {
            avatar: string;
            discriminator: string;
            id: string;
            username: string;
          };
        },
        string
      >({
        query: (discordId: string) => `getDiscordNameById?discordId=${discordId}`,
      }),
      getSpaceId: builder.query({
        query: (address: string) => ({
          url: `getSpaceId?address=${address}`,
          method: 'GET',
        }),
      }),
      getCollectionBySlug: builder.query({
        query: (slug: string) => ({
          url: `getOpenSeaCollectionBySlug?slug=${slug}`,
          method: 'GET',
        }),
      }),
      getEnsByAddress: builder.query<TGetENSResult, string>({
        query: (address: string) => ({
          url: `getEnsByAddress?address=${address}`,
          method: 'GET',
        }),
      }),
      getArticleByMirrorParser: builder.query<{ title: string; content: string; code: number }, string>({
        query: (url: string) => ({ url: `getArticleByMirrorParser?url=${url}`, method: 'GET' }),
      }),

      retriveW3stInfo: builder.query<TW3stMetaData | undefined, string>({
        query: (tokenUri: string) => ({
          url: `retriveW3stInfo?tokenUri=${encodeURIComponent(tokenUri)}`,
          method: 'GET',
        }),
      }),
      getOptions: builder.query<
        TGetOptionsResult,
        { type: 'title' | 'filedOfStudy' | 'degree'; keyword: string; limit?: number }
      >({
        query: (body) => ({
          url: `getOptions`,
          method: 'POST',
          body,
        }),
      }),
    };
  },
});
export default api;
export const {
  useLazyGetTokenLogoQuery,
  useGetTokenLogoQuery,
  useLazyGetTokenPriceQuery,
  useGetTokenPriceQuery,
  useLazyGetTokenPreviewQuery,
  useGetTokenPreviewQuery,
  useGetLinkPreviewQuery,
  useLazyGetLinkPreviewQuery,
  useRetriveAllNFTByAddressQuery,
  useGetPoapInfoByEventIDQuery,
  useGetSpaceIdQuery,
  useLazyGetCollectionBySlugQuery,
  useGetCollectionBySlugQuery,
  useGetEnsByAddressQuery,
  useGetArticleByMirrorParserQuery,
  useLazyGetArticleByMirrorParserQuery,
  useRetriveW3stInfoQuery,
  useLazyRetriveW3stInfoQuery,
  useGetOptionsQuery,
  useLazyGetOptionsQuery,
} = api;
export * from './responesTypes';
