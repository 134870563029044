export const fixHttpLink = (link: string) => {
  if (link.startsWith('http://') || link.startsWith('https://')) return link;

  return `https://${link}`;
};

export const isValidLink = (link?: string) => {
  // ╭──────────────────────────────────────────────────────────╮
  // │                   Generated by ChatGPT                   │
  // ╰──────────────────────────────────────────────────────────╯

  if (!link) return false;
  // Regular expression pattern for a valid URL
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // Protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // Domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP address
      '(\\:\\d+)?(\\/[-a-z\\d%_@.~+]*)*' + // Port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // Fragment locator

  // Return true if the input matches the pattern
  return pattern.test(link);
};

export const isTweetLink: (link: string) => boolean = (link) => {
  const reg = new RegExp(/^https?:\/\/(?:twitter|x)\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/);

  return !!link.match(reg)?.[3];
};
