import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { Input } from '..';

interface IDatePickerProps extends MobileDatePickerProps<Date> {
  showNow?: boolean;
}

export default function DatePicker(props: IDatePickerProps) {
  const { showNow, onChange, ...pickerProps } = props;
  const { value } = pickerProps;

  const isSame = useMemo(() => {
    if (!value) return false;
    return isSameDay(value, new Date());
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        slots={{
          textField: (props) => {
            // @ts-ignore
            return <Input {...props} value={showNow && isSame ? 'Now' : props.value} />;
          },
        }}
        slotProps={{
          actionBar: {
            actions: showNow ? ['today', 'cancel', 'accept'] : ['cancel', 'accept'],
          },
        }}
        maxDate={new Date()}
        {...pickerProps}
        // @ts-ignore
        onAccept={(e = null) => onChange(e)}
      />
    </LocalizationProvider>
  );
}
