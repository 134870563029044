import { Box, BoxProps } from '@mui/material';

if (global.window) {
  require('./init');
}

export interface IconProps extends BoxProps {
  size?: string | number | number[];
  color?: string;
  name: string;
  className?: string;
}

/**
 *
 * @param props.size size of icon, default is 24px
 * @param props.color  color of icon, support mui theme. default is "primary.main"
 * @param props.name symbol of icon,copied from iconfont page
 * 
 *
 * @description go to [iconfont page](https://www.iconfont.cn/manage/index?spm=a313x.7781069.1998910419.22&manage_type=myprojects&projectId=4278649&keyword=&project_type=&page=), select *symbol tab* and copy the icon name
 * 

 */
function Icon(props: IconProps) {
  const { size = 24, color = 'text.primary', name, sx, className = '' } = props;

  return (
    <Box
      {...props}
      className={`icon ${className}`}
      component='span'
      sx={{
        fontSize: size,
        lineHeight: 1,
        maxHeight: size,
        color,
        '&:hover': { cursor: props.onClick ? 'pointer' : 'inherit' },
        ...sx,
      }}
    >
      <svg
        style={{
          width: '1em',
          height: '1em',
          verticalAlign: '-0.15em',
          fill: 'currentColor',
          overflow: 'hidden',
        }}
        aria-hidden='true'
      >
        <use xlinkHref={`#${name}`}></use>
      </svg>
    </Box>
  );
}

export default Icon;
