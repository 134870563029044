import { CyberBundler, CyberFactory } from '@cyberlab/cyber-account';
import { Hash, Hex, concat } from 'viem';
import { Address, useWalletClient } from 'wagmi';
import { useCyberConnect } from '../';
import { cyberBundler } from './V3/bundler';

export type LegacyUserOperation = {
  sender: `0x${string}`;
  nonce: number;
  initCode: `0x${string}`;
  callData: `0x${string}`;
  callGasLimit: bigint;
  verificationGasLimit: bigint;
  preVerificationGas: bigint;
  maxFeePerGas: bigint;
  maxPriorityFeePerGas: bigint;
  paymasterAndData: `0x${string}`;
  signature: `0x${string}`;
};

export type UserOperation = {
  sender: Address;
  nonce: Hex;
  initCode: Hex;
  callData: Hex;
  callGasLimit: Hex;
  verificationGasLimit: Hex;
  preVerificationGas: Hex;
  maxFeePerGas: Hex;
  maxPriorityFeePerGas: Hex;
  paymasterAndData: Hex;
  signature: Hex;
};

type useResendOperationOptions = {
  legacy?: boolean;
};

function useResendOperation(options?: useResendOperationOptions) {
  const { legacy = true } = options || {};
  const { sendUserOperation } = useCyberConnect();
  const client = useWalletClient();

  const reSend = async (
    op: string,
    userOperation: LegacyUserOperation | UserOperation,
    ctx: { chainId: number; owner: Address },
  ) => {
    const signature = await client.data?.signMessage({
      account: ctx.owner,
      message: { raw: op as Hash },
    });

    if (!signature) throw new Error('no signature');

    if (legacy) {
      return await sendUserOperation({ ...(userOperation as LegacyUserOperation), signature }, ctx);
    } else {
      return await cyberBundler.sendUserOperation(
        { ...(userOperation as UserOperation), signature: concat([CyberFactory.validationModes.sudo, signature]) },
        CyberBundler.entryPointAddress,
        ctx.chainId,
      );
    }
  };

  return reSend;
}

export default useResendOperation;
