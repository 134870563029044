import {
  Menu as MenuBase,
  MenuItem as MenuItemBase,
  MenuItemProps,
  MenuProps,
  menuClasses,
  menuItemClasses,
  styled,
} from '@mui/material';

export const Menu: React.FC<MenuProps & { isBlur?: boolean; needBorder?: boolean }> = styled(MenuBase, {
  shouldForwardProp: (prop) => prop !== 'isBlur' && prop !== 'needBorder',
})<{ isBlur?: boolean; needBorder?: boolean }>(
  ({ theme: { spacing, palette }, isBlur, needBorder, anchorEl, ...rest }) => ({
    [`.${menuClasses.paper}`]: {
      minWidth: anchorEl && typeof anchorEl === 'function' ? undefined : anchorEl?.clientWidth,
      backdropFilter: isBlur ? 'blur(10px)' : undefined,
      background: isBlur ? 'rgba(38, 38, 38, 0.50)' : palette.bg?.[100],
      border: needBorder ? `1px solid ${palette.border![300]}` : undefined,
    },
    [`.${menuClasses.list}`]: { padding: spacing(0, 1) },
    // gap: 1,
  }),
);

export const MenuItem: React.FC<MenuItemProps> = styled((props: MenuItemProps) => (
  <MenuItemBase disableRipple {...props} />
))(({ theme: { spacing, palette } }) => ({
  fontSize: '16px',
  lineHeight: '21px',
  minHeight: 21,
  '&:hover': {
    backgorundColor: 'none',
  },
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: 'transparent !important',
    color: palette.brand.main,
    p: {
      fontWeight: 700,
    },
  },
  padding: spacing(1.5, 0),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${palette.border![200]}`,
  },
}));
