import { TransactionData, UserOperationCallData } from '@cyberlab/cyber-account';
import { Address, encodeFunctionData, parseUnits } from 'viem';
import { erc20ABI } from 'wagmi';

type UseApproveProps = {
  spender: Address;
  currency: Address;
  targetChainId?: number;
};
export default function useApprove(props: UseApproveProps) {
  const { spender, currency } = props;

  const makeTransactionData = (allowance: string): UserOperationCallData => {
    const callData = encodeFunctionData({
      abi: erc20ABI,
      functionName: 'approve',
      args: [spender, BigInt(allowance)],
    });
    const transactionData = {
      to: currency,
      data: callData,
      value: 0n,
    };
    return transactionData;
  };
  return { makeTransactionData };
}
