import { StackProps } from '@mui/material';
import Flex from '../Flex';
import Text from '../Text';

interface ProgressProps extends StackProps {
  percentage: number;
  pureProgress?: boolean;
}
export default function Progress(props: ProgressProps) {
  const { color, percentage, pureProgress, ...restProps } = props;
  return (
    <Flex height={6} {...restProps} gap={1} alignItems='center'>
      <Flex bgcolor='bg.100' borderRadius={6 / 4} position='relative' height='100%' width='100%'>
        <Flex
          borderRadius={6 / 4}
          position='absolute'
          top={0}
          left={0}
          bgcolor={color}
          width={`${percentage}%`}
          height='100%'
        ></Flex>
      </Flex>
      {pureProgress ? null : (
        <Text color={color} bold variant='caption'>
          {percentage}%
        </Text>
      )}
    </Flex>
  );
}
