import { WagmiConfig } from '@cyber-co/sdk/wagmi';
import { ReactNode } from 'react';
import { optimism, optimismSepolia } from 'viem/chains';
import { Chain, configureChains, createConfig } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { InjectedConnector } from 'wagmi/connectors/injected';

export type Web3ProviderProps = {
  children: ReactNode;
};
export const projectId = 'f6f98a24cac3dd5951bd14d9283df2a9';
export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [optimism, optimismSepolia],
  [
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain: Chain) => {
        if (chain.id === optimism.id) {
          return {
            http: `https://opt-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`,
          };
        }
        if (chain.id === optimismSepolia.id) {
          return {
            http: `https://optimism-sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`,
          };
        }
        return null;
      },
    }),
  ],
);
const metadata = {
  name: 'Link3',
  description: 'Link3 Web App',
  url: 'https://app.link3.to',
  icons: ['https://app.link3.to/assets/logos/pwa-logo.png'],
};

const connectors = [
  new InjectedConnector({ chains }),
  new WalletConnectConnector({ chains, options: { projectId, metadata } }),
];
const clientForPWA = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

export default function Web3Provider(props: Web3ProviderProps) {
  const { children } = props;

  // if no isClient provided here, WalletConnect will render wrong projectID
  return <WagmiConfig config={clientForPWA}>{children}</WagmiConfig>;
}
