import { formatUnits, parseEther } from 'viem';
import { optimism } from '../configs/chains';
import { CyberId, CyberIdMiddleware } from '../contracts';
import { Address, usePublicClient, useWalletClient } from 'wagmi';

type Params = {
  cyberId: string;
  targetAddress: Address;
  handlePrice: string;
};

export default function useMintCyberId() {
  const publicClient = usePublicClient({
    chainId: optimism.id,
  });
  const { data: walletClient } = useWalletClient();

  const getHandlePrice = async (cyberId: string) => {
    const priceWei = await publicClient.readContract({
      address: CyberIdMiddleware.address,
      abi: CyberIdMiddleware.abi,
      args: [cyberId],
      functionName: 'getPriceWei',
    });
    return formatUnits(((priceWei as bigint) * 101n) / 100n, 18);
  };

  const mintCyberIdWithEoa = async ({ cyberId, handlePrice, targetAddress }: Params) => {
    try {
      const tx = await walletClient?.writeContract({
        address: CyberId.address,
        abi: CyberId.abi,
        functionName: 'register' as const,
        args: [cyberId, targetAddress, '0x0000000000000000000000000000000000000000000000000000000000000000', ''],
        value: parseEther(handlePrice),
      });
      return tx;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return {
    getHandlePrice,
    mintCyberIdWithEoa,
  };
}
