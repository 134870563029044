import { useAuth } from '@/hooks';
import { Address } from '@cyber-co/sdk/viem';
import { truncateAddress } from '@cyber-co/utils';
import { useMeQuery } from '@cyber/service/pwa';

const useAddresses = () => {
  const { isLoggedIn } = useAuth();
  const { data } = useMeQuery(undefined, { skip: !isLoggedIn });

  const eoa: Address = data?.me.data?.privateInfo?.address || '0x';
  const aa = (data?.me.data?.v3Info?.cyberAccount as Address) || ('0x' as Address);
  const assetWallets = data?.me.data?.privateInfo?.assetWallets || [];
  const isPassKeyUser = !!data?.me.data?.turnkeyUserInfo;

  return {
    eoa,
    aa,
    assetWallets: Array.from(new Set([...assetWallets, eoa, aa])), // merge aa eoa and asset wallets
    shortAA: truncateAddress(aa),
    shortEOA: truncateAddress(eoa),
    isPassKeyUser,
  };
};

export default useAddresses;
