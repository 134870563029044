import { StackProps } from '@mui/material';
import Flex from '../Flex';
import Text, { TextProps } from '../Text';

interface TagProps extends TextProps {}

function Tag(props: TagProps & { containerProps?: StackProps }) {
  const { containerProps, ...textProps } = props;
  return (
    <Flex
      bgcolor='bg.100'
      borderRadius={1}
      px={1.5}
      height={28}
      alignItems='center'
      width='fit-content'
      {...containerProps}
    >
      <Text {...textProps} lineHeight={1} variant='caption' color='text.100' />
    </Flex>
  );
}

export default Tag;
