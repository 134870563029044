import { GOOGLE_RECAPTCHA_SCRIPT_URL, GOOGLE_RECAPTCHA_SITEKEY } from '@/configs/constants';
import {
  LinkWeb2Response_Status,
  LocateUserResponse_Status,
  LoginType,
  SendVerificationCodeToEmailRequest_Type,
  SendVerificationCodeToEmailResponse_Status,
  useLinkWeb2Mutation,
  useLocateUserMutation,
  useSendVerificationCodeToEmailMutation,
} from '@cyber/service/pwa';
import { useEffect, useState } from 'react';

interface ICodeResponse {
  status: TStatus | TVerifyStatus;
  message: string;
  token?: string;
  addresses?: string[];
}

type TStatus = SendVerificationCodeToEmailResponse_Status | 'recaptcha_error' | 'other_error';
type TVerifyStatus = LinkWeb2Response_Status | LocateUserResponse_Status | 'other_error';

export function useSendVerificationCode() {
  const [sendCode] = useSendVerificationCodeToEmailMutation();
  const [sendCodeLoading, setSendCodeLoading] = useState<boolean>(false);
  const [verifyCodeLoading, setVerifyCodeLoading] = useState<boolean>(false);
  const [linkWeb2] = useLinkWeb2Mutation();
  const [locateUser] = useLocateUserMutation();

  const getSendCodeMessage = (status: TStatus): string => {
    if (!status) {
      return '';
    }
    const messageMap = {
      [SendVerificationCodeToEmailResponse_Status.EmailUsed]:
        'This email has been linked to an existing account. Please try another one.',
      [SendVerificationCodeToEmailResponse_Status.InvalidEmail]:
        'Email was invalid, please use another email and try again.',
      [SendVerificationCodeToEmailResponse_Status.RecaptchaFailed]: 'Recaptcha load failed, please try again later',
      [SendVerificationCodeToEmailResponse_Status.Success]: 'The verification code has been sent to your email.',
      [SendVerificationCodeToEmailResponse_Status.TooFrequentRequest]:
        'Too many request, please resend after 60 seconds.',
      ['recaptcha_error']: 'Recaptcha not available, please refresh the page and try again.',
      ['other_error']: 'Send verification code failed, please try again',
    };

    return messageMap[status];
  };

  const getVerifyCodeMessage = (
    type: SendVerificationCodeToEmailRequest_Type,
    status: LinkWeb2Response_Status | LocateUserResponse_Status,
  ): string => {
    if (type === SendVerificationCodeToEmailRequest_Type.Link) {
      const linkMessageMap = {
        [LinkWeb2Response_Status.DiscordUsed]:
          'This dicord has been linked to an existing account. Please try another one.',
        [LinkWeb2Response_Status.EmailUsed]:
          'This email has been linked to an existing account. Please try another one.',
        [LinkWeb2Response_Status.InvalidEmail]: 'Email is invalid',
        [LinkWeb2Response_Status.InvalidToken]: 'Invalid Code',
        [LinkWeb2Response_Status.NotLogin]: 'Link failed, please login first',
        [LinkWeb2Response_Status.Success]: 'Link success.',
        [LinkWeb2Response_Status.TwitterUsed]:
          'This twitter has been linked to an existing account. Please try another one.',
        [LinkWeb2Response_Status.WrongCode]: 'Invalid Code',
        [LinkWeb2Response_Status.RateLimited]: 'Rate limit reached. Please try again later.',

        [LinkWeb2Response_Status.AlreadyLinked]: 'AlreadyLinked',
        [LinkWeb2Response_Status.FailedTwitterAuth]: 'FailedTwitterAuth',
        [LinkWeb2Response_Status.TooFrequentAuth]: 'TooFrequentAuth',
        [LinkWeb2Response_Status.LinkedinUsed]: 'This Linked is used.',
        [LinkWeb2Response_Status.GithubUsed]: 'This Github is used.',
      };

      return linkMessageMap[status as LinkWeb2Response_Status];
    } else {
      const loginMessageMap = {
        [LocateUserResponse_Status.EmailUsed]:
          'This email has been linked to an existing account. Please try another one.',
        [LocateUserResponse_Status.InvalidEmail]: 'Email is invalid',
        [LocateUserResponse_Status.InvalidToken]: 'Invalid Code',
        [LocateUserResponse_Status.Success]: '',
        [LocateUserResponse_Status.TokenExpired]: 'Tokenhas expired',
        [LocateUserResponse_Status.WrongCode]: 'Invalid Code',
        [LocateUserResponse_Status.TooFrequentAuth]: 'TooFrequentAuth',
        [LocateUserResponse_Status.FailedTwitterAuth]: 'FailedTwitterAuth',
      };

      return loginMessageMap[status as LocateUserResponse_Status];
    }
  };

  const fireCode = async (
    type: SendVerificationCodeToEmailRequest_Type,
    email: string,
    cb: ({ status, message }: ICodeResponse) => void,
  ) => {
    try {
      setSendCodeLoading(true);
      const _grecaptcha = (window as any).grecaptcha;

      if (_grecaptcha && _grecaptcha.ready) {
        _grecaptcha.ready(function () {
          _grecaptcha.execute(GOOGLE_RECAPTCHA_SITEKEY, { action: 'submit' }).then(async function (token: string) {
            const res = await sendCode({
              type,
              email,
              recaptchaToken: token,
            });

            if ('data' in res) {
              const _status = res.data?.sendVerificationCodeToEmail.status;
              const _message = getSendCodeMessage(_status);
              cb({ status: _status, message: _message });
              setSendCodeLoading(false);
            }
          });
        });
      } else {
        // recaptcha not available
        setSendCodeLoading(false);
        cb({ status: 'recaptcha_error', message: getSendCodeMessage('recaptcha_error') });
      }
    } catch (error) {
      // network & unexpected error
      setSendCodeLoading(false);
      cb({ status: 'other_error', message: getSendCodeMessage('other_error') });
    }
  };

  const verifyCode = async (
    type: SendVerificationCodeToEmailRequest_Type,
    email: string,
    code: string,
  ): Promise<ICodeResponse> => {
    switch (type) {
      case SendVerificationCodeToEmailRequest_Type.Link: {
        try {
          setVerifyCodeLoading(true);
          const res = await linkWeb2({
            type: LoginType.EmailLogin,
            email,
            token: code,
            twitterOAuthCode: { code: '', redirectUri: '' },
          });
          if ('data' in res) {
            const status = res.data?.linkWeb2.status;
            setVerifyCodeLoading(false);
            return { status, message: getVerifyCodeMessage(type, status) };
          }

          setVerifyCodeLoading(false);
          return { status: 'other_error', message: 'Link email failed, please try again later.' };
        } catch (error) {
          setVerifyCodeLoading(false);
          return { status: 'other_error', message: 'Link email failed, please try again later.' };
        }
      }

      case SendVerificationCodeToEmailRequest_Type.Login: {
        try {
          setVerifyCodeLoading(true);
          const res = await locateUser({
            request: { type: LoginType.EmailLogin, email, token: code, twitterOAuth: { code: '', redirectUri: '' } },
          });
          if ('data' in res) {
            const _addresses = res.data?.locateUser.addresses;
            const _tmpToken = res.data?.locateUser.token;
            const _status = res.data?.locateUser.status;

            if (_status) {
              const _message = getVerifyCodeMessage(type, _status);
              setVerifyCodeLoading(false);

              return {
                status: _status,
                message: _message,
                token: _tmpToken ? _tmpToken : '',
                addresses: _addresses ? _addresses : [],
              };
            }
          }

          setVerifyCodeLoading(false);
          return {
            status: 'other_error',
            message: 'Login failed, please try again later.',
            token: '',
            addresses: [],
          };
        } catch (error) {
          setVerifyCodeLoading(false);
          return {
            status: 'other_error',
            message: 'Login failed, please try again later.',
            token: '',
            addresses: [],
          };
        }
      }

      default: {
        return { status: 'other_error', message: 'Operation failed, please try again' };
      }
    }
  };

  // load recaptcha v3 script
  useEffect(() => {
    let _recaptcha = document.querySelector('#recaptcha-script');
    if (_recaptcha) {
      return;
    }

    _recaptcha = document.createElement('script');
    _recaptcha.setAttribute('id', 'recaptcha-script');
    _recaptcha.setAttribute('type', 'text/javascript');
    _recaptcha.setAttribute('src', GOOGLE_RECAPTCHA_SCRIPT_URL);
    _recaptcha.setAttribute('data-size', 'invisible');

    document.body.appendChild(_recaptcha);

    return () => {
      if (_recaptcha) {
        document.body.removeChild(_recaptcha);
      }
    };
  }, []);

  return { sendCodeLoading, verifyCodeLoading, fireCode, verifyCode };
}
