import Icon from '@link3-pwa/icons';
import { DrawerProps } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import Text, { TextProps } from './Text';

const CustomizedDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  paddingBottom: 0,
}));

const CustomizedDialog = styled(Dialog)<{ width?: number | string }>(({ theme, width }) => ({
  margin: 'auto',
  '& .MuiDialog-paper': {
    width,
    maxWidth: '90vw',
    margin: 0,
    borderRadius: '4px',
    background: theme.palette!.bg?.[0],
    border: '1px solid',
    borderColor: theme.palette.border?.[100],
  },
}));

export type ModalProps = {
  title?: React.ReactNode | Element;
  titleProps?: TextProps;
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  noCloseBtn?: boolean;
  width?: number | string;
} & Omit<DialogProps, 'title'> &
  DrawerProps;

const Modal = ({ title, open, onClose, children, noCloseBtn, titleProps, ...rest }: ModalProps) => {
  return (
    <CustomizedDialog open={open} onClose={onClose} {...rest}>
      {title || titleProps?.children ? (
        <CustomizedDialogTitle>
          <Text width='100%' bold variant='subtitle1' color='text.primary' {...titleProps}>
            {title || titleProps?.children}
          </Text>
          {noCloseBtn ? null : <Icon name='icon-close-line' color='#fff' size={24} onClick={onClose} />}
        </CustomizedDialogTitle>
      ) : null}
      <DialogContent sx={{ px: 2, pb: 2 }}>{children}</DialogContent>
    </CustomizedDialog>
  );
};

export default Modal;
