import { useRef } from 'react';

export function useDebounce(fn: any, time: number = 1000) {
  const timer = useRef<any>(null);

  return (...args: any) => {
    if (timer) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        fn(...args);
        timer.current = null;
      }, time);
    }
  };
}
