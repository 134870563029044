import { isUUID } from '../';

export const isValidHandle = (handle: string = '') => {
  const reg = new RegExp(/^[0-9a-z_]{1,20}$/);

  return reg.test(handle);
};

export function getProfileQueryParams(str?: string | string[]) {
  const handle = isUUID(str?.toString()) ? undefined : str?.toString();
  const profileId = isUUID(str?.toString()) ? str?.toString() : undefined;
  return {
    handle,
    id: profileId,
  };
}

export const getProfileRouter = (handleOrId?: string | string[]) => {
  const { handle, id } = getProfileQueryParams(handleOrId);

  if (id) return `/${id}`;

  if (handle) {
    return `/${isValidHandle(handle) ? '' : 'free/'}${handle}`;
  }

  return '';
};
