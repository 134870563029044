import { Icon } from '@link3-pwa/icons';
import { Chip, ChipProps as MuiChipProps } from '@mui/material';
import { COLORS, Flex } from '..';
import Text from '../Text';
interface ChipProps extends MuiChipProps {
  dispalyName: string;
}
export default (props: ChipProps) => {
  return (
    <Chip
      label={
        <Flex alignItems={'center'} gap={1}>
          <Text>{props.dispalyName}</Text>
        </Flex>
      }
      onDelete={props.onDelete}
      sx={{
        p: 0.5,
        mr: 0.5,
        height: 27,
        display: 'flex',
        alignItems: 'center',
        gap: 1.25,
        borderRadius: 1,
        bgcolor: COLORS.brand[800],
        ' .MuiChip-label': {
          p: 0,
        },
        ' .MuiChip-deleteIcon': {
          margin: 0,
          color: '#fff',
        },
      }}
      deleteIcon={<Icon name='icon-close-circle-line' size={18} color='#fff' />}
      size='small'
    ></Chip>
  );
};
