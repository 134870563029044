import 'wagmi/window';
export default async function addCyberToMetaMask() {
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    await window.ethereum?.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: '0x14778860E937f509e651192a90589dE711Fb88a9',
          symbol: 'CYBER',
          decimals: 18,
          image: 'https://etherscan.io/token/images/cyberconnectme2_32.png',
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
}
