import { actions } from '@/store';
import { TRootState } from '@/store/store';
import { formatPaidValueToDollar, sleep } from '@cyber-co/utils';
import {
  ChatPriceStatus,
  useLazyMessagePriceQuery,
  useMessagePriceQuery,
  useRefreshChatPriceMutation,
} from '@cyber/service/pwa';
import { useDispatch, useSelector } from 'react-redux';

type MessagePriceProps = {
  userId?: string;
};

export default function useMessagePrice(props: MessagePriceProps) {
  const dispatch = useDispatch();
  const { userId } = props;
  const isRefreshingPrice = useSelector((state: TRootState) => state.message.isRefreshingPrice);
  const [refreshCaller, { isLoading: refreshPriceLoading }] = useRefreshChatPriceMutation();
  const { data, refetch, isFetching, status } = useMessagePriceQuery({ id: userId! }, { skip: !userId });
  const [getPriceCaller] = useLazyMessagePriceQuery();

  const refreshMessagePrice = async () => {
    if (userId) {
      dispatch(actions.message.setIsRefreshingPrice(true));
      let isRefreshed = false;
      await refreshCaller({ userId: userId });
      do {
        await sleep(1000);
        const res = await getPriceCaller({ id: userId });
        if (res.data?.me.data?.chat?.price.priceStatus === ChatPriceStatus.Success) {
          isRefreshed = res.data?.me.data?.chat?.price.priceStatus === ChatPriceStatus.Success;
          break;
        }
      } while (!isRefreshed);
      dispatch(actions.message.setIsRefreshingPrice(false));
      if (refetch) {
        return await refetch();
      } else {
        return null;
      }
    }
    return false;
  };

  const priceObj = data?.me.data?.chat?.price.price;
  const price = formatPaidValueToDollar(priceObj?.value, priceObj?.decimal);
  const priceStatus = data?.me.data?.chat?.price.priceStatus;

  // useEffect(() => {
  //   if (!isRefreshingPrice && priceStatus && priceStatus !== ChatPriceStatus.Success && !refreshPriceLoading) {
  //     console.log('--开始refresh price--');
  //     refreshMessagePrice();
  //   }
  // }, [priceStatus, refreshPriceLoading, isRefreshingPrice]);

  return {
    price,
    priceObj,
    priceStatus,
    refreshMessagePrice,
    refetch,
    fetchLoading: isFetching || refreshPriceLoading,
  };
}
