import { Box, SxProps } from '@mui/material';
import React from 'react';

interface IRatioBoxBox {
  ratio?: number | 'auto';
  width?: string | number;
  children?: React.ReactNode | React.ReactNode[];
  sx?: SxProps;
}
/**
 * @param ratio  height / width
 */
const RatioBox = ({ ratio = 1, width = '100%', children, sx }: IRatioBoxBox) => {
  const isResponsiveBox = ratio === 'auto';

  return (
    <Box
      sx={{
        position: 'relative',
        width,
        ...sx,
      }}
    >
      {isResponsiveBox ? (
        children
      ) : (
        <Box sx={{ paddingTop: `${(ratio as unknown as number) * 100}%` }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RatioBox;
