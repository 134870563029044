import { bufferToBase64url } from '@cyber-co/utils';
import {
  RegisterTurnkeyUserRequest,
  TurnkeyRequestType,
  TurnkeyUserLoginQuery,
  useLazyTurnkeyUserLoginQuery,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { TurnkeyClient, getWebAuthnAttestation } from '@turnkey/http';
import { createAccount } from '@turnkey/viem';
import { WebauthnStamper } from '@turnkey/webauthn-stamper';
import { useState } from 'react';
import { Address, Hash, getAddress } from 'viem';
import { useIsAndroid } from '../platform';
import { getRpId } from './getRpId';

const generateRandomBuffer = (): ArrayBuffer => {
  const arr = new Uint8Array(32);
  crypto.getRandomValues(arr);
  return arr.buffer;
};

function usePasskey() {
  const [turnkeyLogin] = useLazyTurnkeyUserLoginQuery();

  const isAndroid = useIsAndroid();

  const [isCreating, toggleIsCreating] = useState(false);
  const [isLogining, toggleIsLogining] = useState(false);

  const getPasskeyHttpClient = () => {
    const stamper = new WebauthnStamper({
      rpId: getRpId(),
    });

    return new TurnkeyClient(
      {
        baseUrl: process.env.NEXT_PUBLIC_TURNKEY_ENDPOINT!,
      },
      stamper,
    );
  };

  const handleLogin = async ({
    onSuccess,
  }: {
    onSuccess: (user: TurnkeyUserLoginQuery['turnkeyUserLogin']['user']) => void;
  }) => {
    try {
      toggleIsLogining(true);
      const passkeyHttpClient = getPasskeyHttpClient();

      const params = await passkeyHttpClient
        .stampGetWhoami({
          organizationId: process.env.NEXT_PUBLIC_TURNKEY_ORG_ID!,
        })
        .catch((e) => console.log(e));

      if (!params) throw new Error('Something went wrong when sign in with passkey');

      const user = await turnkeyLogin({
        request: {
          ...params,
          type: TurnkeyRequestType.WhoAmI,
        },
      }).then((res) => res.data?.turnkeyUserLogin.user);

      if (user?.id) {
        onSuccess(user);
      }
    } catch (error) {
      console.log(error);
    } finally {
      toggleIsLogining(false);
    }
  };

  const handleCreate = async ({
    username,
    register,
  }: {
    username: string;
    register: (request: RegisterTurnkeyUserRequest) => Promise<{ isSuccess: boolean }>;
  }) => {
    try {
      toggleIsCreating(true);

      const challenge = generateRandomBuffer();

      const authenticatorUserId = generateRandomBuffer();

      const attestation: any = await getWebAuthnAttestation({
        publicKey: {
          rp: {
            id: getRpId(),
            name: 'Link3 App',
          },
          challenge: challenge,
          pubKeyCredParams: [
            {
              type: 'public-key',
              alg: -7,
            },
          ],
          user: {
            id: authenticatorUserId,
            name: username,
            displayName: username,
          },
          authenticatorSelection: {
            residentKey: isAndroid ? 'preferred' : undefined,
            userVerification: isAndroid ? 'preferred' : undefined,
            authenticatorAttachment: isAndroid ? 'platform' : undefined,
          },
        },
      });

      if (!attestation) {
        throw new Error('Something went wrong in creating passkey');
      }

      const { isSuccess } = await register({
        attestation,
        authenticatorName: username,
        challenge: bufferToBase64url(challenge),
      });

      return {
        isSuccess,
      };
    } catch (error) {
      toast.error(error);
      return { isSuccess: false };
    } finally {
      toggleIsCreating(false);
    }
  };

  const signMessage = async (params: { address: Address; orgId: string; message: Hash }) => {
    const passkeyHttpClient = getPasskeyHttpClient();

    const viemAccount = await createAccount({
      client: passkeyHttpClient as any,
      signWith: getAddress(params.address),
      organizationId: params.orgId,
    });

    return await viemAccount.signMessage({
      message: { raw: params.message },
    });
  };

  return {
    handleCreate,
    signMessage,
    handleLogin,
    isCreating,
    isLogining,
  };
}

export default usePasskey;
