import { Address, useBalance } from '@cyber-co/sdk/wagmi';
import useAddresses from './useAddresses';
import { optimism } from '@cyber-co/sdk/configs/chains';
import { useMeQuery } from '@cyber/service/pwa';
import { formatPaidValueToDollar } from '@cyber-co/utils';

// deperacted
export function useLagecyAccountBalance() {
  const { aa } = useAddresses();
  const { data, isLoading, refetch, isFetching } = useBalance({
    token: process.env.NEXT_PUBLIC_USDT_ADDRESS! as Address,
    address: aa,
    chainId: optimism.id,
    formatUnits: 6,
    enabled: aa !== '0x',
  });
  return { data, isLoading: isLoading || isFetching, refetch };
}
export default function useAccountBalance({ isPolling = false }: { isPolling?: boolean }) {
  const { data, refetch, isLoading, isFetching } = useMeQuery(undefined, { pollingInterval: isPolling ? 3000 : 0 });
  const balanceObj = data?.me.data?.dmCreditAccount?.balance;
  const withdrawableBalanceObj = data?.me.data?.dmCreditAccount?.withdrawableBalance;
  const unavailableBalanceObj = data?.me.data?.dmCreditAccount?.nonWithdrawableBalance;
  return {
    balance: { formatted: formatPaidValueToDollar(balanceObj?.value, balanceObj?.decimal), value: balanceObj },
    withdrawableBalance: {
      formatted: formatPaidValueToDollar(withdrawableBalanceObj?.value, withdrawableBalanceObj?.decimal),
      value: withdrawableBalanceObj,
    },
    unavailableBalance: {
      formatted: formatPaidValueToDollar(unavailableBalanceObj?.value, unavailableBalanceObj?.decimal),
      value: unavailableBalanceObj,
    },
    refetch,
    isLoading: isLoading || isFetching,
  };
}
