import { useMemo } from 'react';
import { Address } from 'viem';
import { WalletClient, useContractRead, useWalletClient } from 'wagmi';
import { ERC20ABI } from '../../configs/erc20Abi';
import { signPermit } from './permit';

function useSignPermit({
  spender,
  owner,
  currency,
  chainId,
  value,
}: {
  spender: Address;
  owner?: Address;
  currency: Address;
  chainId?: number;
  enabled?: boolean;
  value?: bigint;
}) {
  const { data: walletClient } = useWalletClient();

  // there is no nonces function in OP USDT, set to 1n when nonce is undefined
  const { data: nonce = 1n } = useContractRead({
    address: currency as Address,
    abi: ERC20ABI,
    functionName: 'nonces',
    chainId,
    args: [owner] as [Address],
    enabled: !!owner && !!currency,
  });

  const { data: erc20Name } = useContractRead({
    address: currency as Address,
    abi: ERC20ABI,
    functionName: 'name',
    chainId,
    enabled: !!owner && !!currency,
  });

  // same reason with nonce
  const { data: permitVersion = '1' } = useContractRead({
    address: currency as Address,
    abi: ERC20ABI,
    functionName: 'version',
    chainId,
    enabled: !!owner && !!currency,
  });

  const isReady =
    walletClient !== undefined &&
    owner !== undefined &&
    erc20Name !== undefined &&
    permitVersion !== undefined &&
    nonce !== undefined &&
    chainId !== undefined &&
    value !== undefined;

  const sign = useMemo(() => {
    if (!isReady) {
      return undefined;
    }

    return ({ deadline }: { deadline: bigint }) =>
      signPermit(walletClient as WalletClient, {
        contractAddress: currency,
        ownerAddress: owner,
        erc20Name,
        nonce,
        permitVersion,
        spenderAddress: spender,
        chainId,
        deadline,
        value,
      });
  }, [isReady]);

  return {
    signPermit: sign,
    isReady,
  };
}

export default useSignPermit;
