import { useGetTokenPriceQuery } from '@cyber/service/api';

//  {
//   "code": 200,
//   "data": {
//       "id": 1027,
//       "symbol": "ETH",
//       "name": "Ethereum",
//       "amount": 1,
//       "last_updated": "2024-02-27T06:50:00.000Z",
//       "quote": {
//           "USD": {
//               "price": 3233.2697099198067,
//               "last_updated": "2024-02-27T06:50:00.000Z"
//           }
//       }
//   }
// }
export default function useETHPrice() {
  const { data } = useGetTokenPriceQuery('1027');
  return { price: Number(data?.data.quote.USD.price || 0) };
}
