const TWITTER_SCOPES = ['tweet.read', 'users.read', 'follows.read', 'offline.access', 'like.read'];

export const redirectGetTwitterCode = (from: string = location.pathname) => {
  const redirectUri = window.origin + '/redirect/twitter';
  const state = `from=${encodeURIComponent(from)}`;
  const clientId =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? 'Z2xOSFY1V0ZMVUJMNW44M1FPQUY6MTpjaQ'
      : 'cEc1b0hYajkwNkpiQ2t5cWtjVlg6MTpjaQ';
  const url = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${TWITTER_SCOPES.join(
    '%20',
  )}&state=${state}&code_challenge=challenge&code_challenge_method=plain`;

  window.open(url, '_blank');
  // window.open(url, 'twitterVerify', 'width=600,height=1024,resizable=yes,popup');
};

export function decodeState(state: string): string {
  if (!state) return '';
  return state.split('from=')[1] || '/me/account-setting/social-media';
}

export const postInTwitter = (text: string) => {
  // window.location.href = `twitter://post?message=${encodeURIComponent(text)}`;
  window.location.href = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
};
