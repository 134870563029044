import { setPayLoading } from '@/store/reducers/message';
import { useApprove } from '@cyber-co/sdk';
import { Address, encodeFunctionData, hexToBigInt, parseUnits } from '@cyber-co/sdk/viem';
import { useState } from 'react';
import { useV3Sdk } from '../useV3Sdk';
import { optimism } from '@cyber-co/sdk/configs/chains';
import useAddresses from '../useAddresses';
import { CyberVaultContract } from '@/configs/contracts';
import { isDev } from '@/configs/constants';
import { EstimateUserOperationReturn } from '@cyber-co/sdk/cyberAccount';
import {
  InitPaidMessageResponse_Status,
  MessageType,
  PriceFragment,
  useInitPaidMessageMutation,
  useLazyMessageQuery,
} from '@cyber/service/pwa';
import { toast } from '@cyberlab/uikit';
import { useSelector } from 'react-redux';
import { TRootState } from '@/store/store';
import { useDispatch } from 'react-redux';
import { actions } from '@/store';
import useMessagePrice from './useMessagePrice';

type PayMessageProps = {
  chatId: string;
};

export default function usePayMessage({ chatId }: PayMessageProps) {
  const dispatch = useDispatch();
  const [_estimatedGas, setEstimatedGas] = useState<EstimateUserOperationReturn | null>(null);
  const [estimateLoading, setEstimateLoading] = useState(false);
  const payLoading = useSelector((state: TRootState) => state.message.payLoading);
  const [errorMsg, setErrorMsg] = useState('');
  const cyberAccount = useV3Sdk({ chainId: optimism.id });
  const { aa } = useAddresses();

  const [initCaller] = useInitPaidMessageMutation();
  const [messageCaller] = useLazyMessageQuery();

  const { refetch: refetchPrice, refreshMessagePrice } = useMessagePrice({ userId: chatId });

  const setPayLoading = (flag: boolean) => {
    dispatch(actions.message.setPayLoading(flag));
  };

  const { makeTransactionData } = useApprove({
    spender: CyberVaultContract.address,
    currency: process.env.NEXT_PUBLIC_USDT_ADDRESS as Address,
  });

  const makeUserOpCallData = (amount: string) => {
    if (!aa) {
      return null;
    }
    const _amount = BigInt(amount);
    const callData = encodeFunctionData({
      abi: CyberVaultContract.abi,
      functionName: 'depositERC20',
      // from,to,currency,amount，from===to=aa
      args: [aa, aa, process.env.NEXT_PUBLIC_USDT_ADDRESS, _amount],
    });
    const approveCallData = makeTransactionData(amount);
    const userOpData = [
      approveCallData,
      {
        to: CyberVaultContract.address,
        data: callData,
        value: 0n,
      },
    ];
    return userOpData;
  };

  const initMessage = async ({
    price,
    content,
    credit,
  }: {
    price?: PriceFragment;
    content: string;
    credit?: PriceFragment;
  }) => {
    setPayLoading(true);
    const refreshMessageRes = await refreshMessagePrice();
    if (refreshMessageRes && 'data' in refreshMessageRes) {
      const latestPrice = refreshMessageRes.data?.me.data?.chat?.price.price.value;
      if (latestPrice === price?.value || latestPrice === credit?.value) {
        const res = await initCaller({
          input: {
            chainId: optimism.id,
            message: { chatId, content, msgType: MessageType.Text },
            value: price,
            credit,
          },
        });
        setPayLoading(false);
        if ('data' in res) {
          if (res.data.initPaidMessage.status === InitPaidMessageResponse_Status.Success) {
            return { messageId: res.data.initPaidMessage.message?.id };
          } else {
            toast(res.data.initPaidMessage.status, { type: 'error' });
            return false;
          }
        }
      } else {
        toast.warning('Price has changed, please confirm again');
      }
    } else {
      toast.error('Get latest price failed, please try again');
    }
    setPayLoading(false);
    return false;
  };

  const estimate = async ({ amount, sponsorSig }: { amount: string; sponsorSig?: string }) => {
    const userOpData = makeUserOpCallData(amount);
    if (userOpData) {
      try {
        setEstimateLoading(true);
        const res = await cyberAccount?.estimateTransaction(userOpData, { sponsorSig });
        setEstimatedGas(res as EstimateUserOperationReturn);
      } catch (e: any) {
        setErrorMsg(e);
        console.log(e);
      } finally {
        setEstimateLoading(false);
      }
    } else {
      setErrorMsg('CyberAccount is empty');
    }
  };

  // const sendTransaction = async ({
  //   amount,
  //   chatId,
  //   msgId,
  //   sponsorSig,
  // }: {
  //   amount: string;
  //   chatId: string;
  //   msgId: string;
  //   sponsorSig: string;
  // }) => {
  //   const userOpData = makeUserOpCallData(amount);
  //   if (userOpData) {
  //     setPayLoading(true);
  //     try {
  //       const timeout = 30000;
  //       const abortController = new AbortController();
  //       const timeoutPromise = new Promise((resolve) => {
  //         setTimeout(() => {
  //           abortController.abort();
  //           resolve(false);
  //         }, timeout);
  //       });
  //       const userOpPromise = cyberAccount?.sendTransaction(userOpData, { sponsorSig });

  //       const userOpHash = await Promise.race([userOpPromise, timeoutPromise]);

  //       if (userOpHash) {
  //         const res = await ackCaller({ input: { chatId, msgId } });
  //         await messageCaller({ id: msgId });
  //         refetchPrice();
  //         if ('data' in res) {
  //           return { isSuccess: true };
  //         }
  //         return { isSuccess: false };
  //       }
  //     } catch (e: any) {
  //       setErrorMsg(e);
  //       console.log(e);
  //       return { isSuccess: false };
  //     } finally {
  //       setPayLoading(false);
  //     }
  //   }
  //   return { isSuccess: false };
  // };

  return {
    _estimatedGas,
    estimateLoading,
    payLoading,
    estimate,
    errorMsg,
    initMessage,
  };
}
