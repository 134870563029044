export * from './discord';
export * from './event';
export * from './getAuthorFromProfile';
export * from './image';
export * from './twitter';
export * from './device';

export const revalidateSSGPage = (handle: string) => {
  fetch('https://link3.to/api/revalidate?handle=' + handle).catch((e) => e);
};
