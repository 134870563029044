import { Icon } from '@link3-pwa/icons';
import { SelectProps as MuiSelectProps, Select as SelectBase, Stack, SxProps } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import Input from '../Input';
import { MenuItem } from '../Menu';
import Text from '../Text';

type SelectOption = {
  readonly label: ReactNode;
  readonly value: string | number;
};

export interface SelectProps extends MuiSelectProps {
  enableCheckSelected?: boolean;
  options: SelectOption[];
  MenuPaperSx?: SxProps;
}

const DropDownIcon = (props: any) => <Icon {...props} name='icon-arrow-tiny-down' color='text.secondary' />;

function Select(props: SelectProps) {
  const {
    enableCheckSelected = true,
    options = [],
    MenuPaperSx = {},
    MenuProps = {},
    IconComponent = DropDownIcon,
    ...selectProps
  } = props;

  const optionItems = useMemo(() => {
    return options.map((opt, index) => {
      return (
        <MenuItem
          key={opt.value}
          value={opt.value}
          sx={{
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Stack width='100%'>{typeof opt.label === 'string' ? <Text>{opt.label}</Text> : opt.label}</Stack>
        </MenuItem>
      );
    });
  }, [options, selectProps]);

  const _menuProps: any = useMemo(() => {
    return {
      ...MenuProps,
      sx: ({ palette, spacing }: any) => {
        return {
          minWidth:
            MenuProps.anchorEl && typeof MenuProps.anchorEl === 'function'
              ? undefined
              : MenuProps.anchorEl?.clientWidth,
          '.MuiMenu-paper': {
            maxHeight: 360,
            mt: 1,
            bgcolor: 'bg.100',
            // Note: 忘了这里为啥要-0.5，如果有问题再改回来
            // marginLeft: -0.5,
            ...MenuPaperSx,
          },
          ul: {
            padding: spacing(1.5),
          },
          '.Mui-selected': {
            color: 'brand.main',
            backgroundColor: 'none',
            fontWeight: 700,
          },
        };
      },
    };
  }, [MenuProps, MenuPaperSx]);

  return (
    <SelectBase
      MenuProps={_menuProps}
      IconComponent={IconComponent}
      input={<Input />}
      // renderValue={(value) => {
      //   return <Text variant='body1'>{value}</Text>;
      // }}
      {...selectProps}
    >
      {optionItems}
    </SelectBase>
  );
}

export default Select;
