import { generateUUID, isJsonString } from '@cyber-co/utils';

export const setDeviceId = () => {
  const uuid = generateUUID();
  localStorage.device_id = uuid;
  return uuid;
};
export const getDeviceId = () => {
  if (!localStorage.device_id) {
    const uuid = setDeviceId();
    return uuid;
  } else {
    return localStorage.device_id as string;
  }
};
export const getFcmToken = (userId: string) => {
  const localFcmToken = localStorage.fcmToken;
  if (isJsonString(localFcmToken)) {
    const fcmTokenObj = JSON.parse(localFcmToken);
    return fcmTokenObj[userId];
  }
};
export const setFcmToken = (userId: string, token: string) => {
  const localFcmToken = localStorage.fcmToken;
  if (isJsonString(localFcmToken)) {
    const fcmTokenObj = JSON.parse(localFcmToken);
    fcmTokenObj[userId] = token;
    localStorage.fcmToken = JSON.stringify(fcmTokenObj);
  }
};

export const deleteFcmToken = (userId: string) => {
  const localFcmToken = localStorage.fcmToken;
  if (isJsonString(localFcmToken)) {
    const fcmTokenObj = JSON.parse(localFcmToken);
    delete fcmTokenObj[userId];
    localStorage.fcmToken = JSON.stringify(fcmTokenObj);
  }
};
