import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '../graphqlBaseQuery';

export const api = createApi({
  reducerPath: 'galaxyAPi',
  baseQuery: graphqlRequestBaseQuery({
    url: 'https://graphigo.prd.galaxy.eco/query',
  }),
  endpoints: () => ({}),
});
