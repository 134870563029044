import {
  FollowResponse_Status,
  UnFollowResponse_Status,
  useFollowMutation,
  useUnfollowMutation,
} from '@cyber/service/pwa';
import { useCallback, useMemo, useState } from 'react';
import { toast } from '@cyberlab/uikit';

type TFollowResponse = {
  isError?: boolean;
  status?: FollowResponse_Status;
  message?: string;
  isSuccess?: boolean;
};

type FollowFunc = (profileId: string) => Promise<TFollowResponse>;
type unFollowFunc = (profileId: string) => Promise<TFollowResponse>;

const MSG: {
  [key in FollowResponse_Status]?: string;
} = {
  // [FollowResponse_Status.HandleNotFound]: 'You could not follow this profile temporarily',
  [FollowResponse_Status.AlreadyDone]: "You've already followed this profile",
  [FollowResponse_Status.RateLimited]: 'Rate limit reached. Please try again later.',
};
const UNFOLLOW_MSG: {
  [key in UnFollowResponse_Status]?: string;
} = {
  // [UnFollowResponse_Status.HandleNotFound]: 'You could not unfollow this profile temporarily',
  [UnFollowResponse_Status.AlreadyDone]: "You've already unfollowed this profile",
  [UnFollowResponse_Status.RateLimited]: 'Rate limit reached. Please try again later.',
};

function useFollow(): {
  follow: FollowFunc;
  isLoading: boolean;
  unFollow: unFollowFunc;
} {
  const [isLoading, toggleIsLoading] = useState(false);
  const [followCaller] = useFollowMutation();
  const [unFollowCaller] = useUnfollowMutation();

  const follow: FollowFunc = useCallback(async (profileId) => {
    toggleIsLoading(true);
    const result = await followCaller({
      profileId,
    }).then((res) => {
      if ('data' in res) {
        const isSuccess = res.data.follow.status === FollowResponse_Status.Success;
        if (isSuccess) {
          return {
            isSuccess,
            message: res.data.follow.status,
          };
        } else {
          toast.error(res.data.follow.status);
          return {
            isSuccess,
            message: res.data.follow.status,
          };
        }
      }

      return {
        isSuccess: false,
        message: 'unknown error',
      };
    });

    toggleIsLoading(false);

    if (result.isSuccess) {
      return { isSuccess: true };
    } else {
      return {
        isError: true,
        message: MSG[result?.message as FollowResponse_Status] || 'Network busy. Please try again later.',
      };
    }
  }, []);

  // TODO: error toast

  const unFollow: unFollowFunc = useCallback(async (profileId) => {
    toggleIsLoading(true);
    const result = await unFollowCaller({ profileId }).then((res) => {
      if ('data' in res) {
        return {
          isSuccess: res.data.unfollow.status === UnFollowResponse_Status.Success,
          message: res.data.unfollow.status,
        };
      }

      return {
        isSuccess: false,
        message: 'unknown error',
      };
    });
    toggleIsLoading(false);

    if (result.isSuccess) {
      return { isSuccess: true };
    } else {
      return {
        isError: true,
        message: UNFOLLOW_MSG[result?.message as UnFollowResponse_Status] || 'Network busy. Please try again later.',
      };
    }
  }, []);

  // const enhancedFollow = useCheckWalletAddress({ cb: follow });
  // const enhancedUnFollow = useCheckWalletAddress({ cb: unFollow });

  return useMemo(
    () => ({
      isLoading,
      follow,
      unFollow,
    }),
    [isLoading, follow, unFollow],
  );
}

export default useFollow;
