import { Icon } from '@link3-pwa/icons';
import { Avatar, Popover, Stack, StackProps } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { Flex } from '..';

interface IAvatarGroupProps extends StackProps {
  avatars?: string[];
  variant?: 'circular' | 'rounded' | 'square';
  size?: number;
  limit?: number;
}

const MoreIcon = ({
  size,
  moreAvatars,
  variant,
}: {
  size: number;
  moreAvatars: string[];
  variant: IAvatarGroupProps['variant'];
}) => {
  const [anchorEle, setAnchorEle] = useState<HTMLDivElement>();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    setAnchorEle(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEle(undefined);
  };

  return (
    <>
      <Stack
        justifyContent='center'
        alignItems='center'
        ml={`${-size / 2}px`}
        bgcolor='primary.contrastText'
        width={size}
        height={size}
        borderRadius={100}
        border='1px solid'
        zIndex={2}
        borderColor='bg.0'
        onClick={handleClick}
      >
        <Icon name='icon-more-line' size={size * 0.8} sx={{ userSelect: 'none', pointerEvents: 'none' }} />
      </Stack>

      <Popover
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        open={!!anchorEle}
        anchorEl={anchorEle}
      >
        <Flex p={1} gap={0.5} flexWrap='wrap' maxWidth={(size + 2) * 5 + 4 * 4 + 8}>
          {moreAvatars.map((avatar, i) => (
            <Avatar
              key={avatar}
              variant={variant}
              sx={{
                width: size,
                height: size,
                border: '1px solid',
                borderColor: 'bg.0',
              }}
              src={avatar}
            />
          ))}
        </Flex>
      </Popover>
    </>
  );
};
function AvatarGroup(props: IAvatarGroupProps) {
  const { avatars = [], variant = 'circular', size = 24, limit, ...wrapperProps } = props;

  const renderAvatars = () => (
    <>
      {avatars.slice(0, limit || avatars.length).map((avatar, i) => (
        <Avatar
          key={avatar + i}
          variant={variant}
          sx={{
            width: size,
            height: size,
            border: '1px solid',
            borderColor: 'border.100',
            marginLeft: i === 0 ? 0 : `${-size / 2}px`,
          }}
          src={avatar}
        />
      ))}
      {limit && limit > 0 && limit < avatars.length ? (
        <MoreIcon moreAvatars={avatars.slice(limit)} size={size} variant={variant} />
      ) : null}
    </>
  );

  return avatars.length > 0 ? <Flex {...wrapperProps}>{renderAvatars()}</Flex> : null;
}

export default AvatarGroup;
