import { sleep } from '@cyber-co/utils';
import { Hash } from 'viem';
import { usePublicClient } from 'wagmi';

export default function usePollingGetTxHash({ chainId }: { chainId: number }) {
  const publicClient = usePublicClient({ chainId });
  const pollingGetTxHash = async (tx: Hash) => {
    let isDone = false,
      count = 0;

    do {
      count = count + 1;

      await sleep(3000);
      try {
        const res = await publicClient.waitForTransactionReceipt({
          hash: tx,
        });
        isDone = true;
        return res;
      } catch (e) {
        if (count < 60) {
          continue;
        }
        isDone = true;
      }
    } while (!isDone);
  };
  return { pollingGetTxHash };
}
