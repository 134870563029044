import { LoadingButtonProps } from '@mui/lab';
import { ThemeOptions } from '@mui/material/styles';
import { buttonStyle } from './buttonStyle';

declare module '@mui/material/styles/components' {
  interface Components {
    MuiLoadingButton?: {
      defaultProps?: Partial<LoadingButtonProps>;
    };
  }
}
export const genComponents = (palette: any): ThemeOptions['components'] => {
  return {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          backgroundColor: theme.palette.background.default,
          '&::-webkit-scrollbar': {
            display: 'none',
            // width: 6,
            backgroundColor: 'transparent',
          },
        },
      }),
    },
    MuiButton: buttonStyle,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // helps disable ripple in Chip
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingPosition: 'start',
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '2px',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        // root: ({ theme }) => ({
        //   padding: theme.spacing(1),
        //   transition: 'all .3s ease',
        //   boxShadow: theme.cyberShadow.drop1,
        // }),
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {},
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none !important',
          // boxShadow: '0px 20px 40px 0px rgba(0, 0, 0, 0.10)',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          zIndex: 99,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          '& .SnackbarItem-variantInfo': {
            backgroundColor: '#000 !important',
          },
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: palette.text[250],
          },
        },
        multiline: {
          '&::placeholder': {
            color: palette.text[250],
          },
        },
      },
    },

    MuiTextField: {},
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
};
