import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import _get from 'lodash/get';
import { useCallback, useState } from 'react';

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  EVMAddress: any;
  Timestamp: any;
  Upload: any;
  Url: any;
};

type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};
type Return<ReqParam, ListData> = [
  (param: ReqParam, reset?: boolean, preferCacheValue?: boolean) => any,
  { list: ListData[]; pageInfo: PageInfo | undefined; [key: string]: any },
  () => void,
];

const usePager = <Q = never, S = never>(lazyHook: UseLazyQuery<any>, keyPath: string): Return<Q, S> => {
  const [lazyFetcher, result] = lazyHook();
  const [list, setList] = useState<S[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>();

  const fetcher = useCallback(
    (fetchParam, reset = false, preferCacheValue?: boolean) => {
      if (result.isFetching) return;

      if (reset) {
        setList([]);
        setPageInfo(undefined);
      }

      lazyFetcher(
        {
          ...fetchParam,
          ...(reset ? undefined : { after: pageInfo?.endCursor || '' }),
        },
        preferCacheValue,
      ).then((res: any) => {
        if (res.error) return;

        const { list: newList, pageInfo: newPageInfo } = _get(res, ['data', ...keyPath.split('.')]) || {};
        if (newList) setList([...(reset ? [] : list), ...newList]);
        if (newPageInfo) {
          setPageInfo(newPageInfo);
        } else if (reset) {
          setPageInfo(undefined);
        }
      });
    },
    [result, lazyFetcher, list, pageInfo, keyPath],
  );

  const reset = useCallback(() => {
    setList([]);
    setPageInfo(undefined);
  }, []);

  // same signature as lazy hook
  return [fetcher, { ...result, list, pageInfo }, reset];
};

export default usePager;
