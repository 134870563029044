import Providers from '@/component/Providers';
import { wrapper } from '@/store/store';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { ReactElement, ReactNode } from 'react';
import '../styles/global.css';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// if (process.env.NODE_ENV === 'development') {
//   require('../mocks');
// }

function Link3({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
        />
      </Head>
      <Providers>{getLayout(<Component {...pageProps} />)}</Providers>

      <Script id='gtag' strategy='beforeInteractive' src='https://www.googletagmanager.com/gtag/js?id=G-7LT2J4PEZB' />
      <Script
        id='ga-analytics'
        strategy='beforeInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-7LT2J4PEZB', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
}

export default wrapper.withRedux(Link3);
