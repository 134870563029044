import { Icon } from '@link3-pwa/icons';
import { Stack, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import Flex from '../Flex';
import Text from '../Text';

export type NavBarProps = {
  title?: string | ReactNode;
  subtitle?: ReactNode | null;
  onBack?: () => void;
  suffix?: ReactNode;
  containerSx?: SxProps;
  divider?: boolean;
};

function NavBar(props: NavBarProps) {
  const { onBack, title, suffix, subtitle = null, containerSx = {}, divider = false } = props;

  const renderContent = () => {
    return typeof title === 'string' ? (
      <Text textAlign='center' width='100%' variant='subtitle1' bold sx={{ userSelect: 'none', pointerEvents: 'none' }}>
        {title}
      </Text>
    ) : (
      title
    );
  };
  return (
    <Stack
      sx={{
        ...containerSx,
        borderBottom: divider ? '1px solid' : 'none',
        borderColor: 'border.100',
        position: 'sticky',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 10,
      }}
    >
      <Stack alignItems='center' height={56} flexDirection='row' px={2} bgcolor='bg.paper'>
        {typeof onBack === 'function' ? (
          <Icon
            onClick={onBack}
            name='icon-arrrow-tiny-up'
            size={36}
            sx={{ transform: 'rotate(-90deg)', flex: 0, cursor: 'pointer', mt: -6 / 16, ml: -6 / 16 }}
          />
        ) : null}

        {renderContent()}

        {suffix || onBack ? (
          <Flex width={36} height={36} position='relative' alignItems='center' justifyContent='center'>
            {suffix}
          </Flex>
        ) : null}
      </Stack>
      {subtitle}
    </Stack>
  );
}

export default NavBar;
