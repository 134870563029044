import { useEffect, useState } from 'react';
import { parseUnits } from 'viem';
import { Address, erc20ABI, useContractRead, usePublicClient, useWalletClient } from 'wagmi';

// only for v2 post bsc approve
export default function useEOAApprove({
  spender,
  address,
  currency,
  allowance,
  decimals,
  targetChainId,
  enabled = true,
}: {
  spender: Address;
  address: Address;
  currency: Address;
  allowance: string | number;
  decimals: number;
  targetChainId?: number;
  enabled?: boolean;
}) {
  const { data: walletClient } = useWalletClient({ chainId: targetChainId });
  const publicClient = usePublicClient();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    refetch,
    isLoading: fetchAllownanceLoading,
    isFetching,
    isFetched,
  } = useContractRead({
    address: currency as Address,
    abi: erc20ABI,
    functionName: 'allowance' as const,
    chainId: targetChainId,
    args: [address, spender] as [Address, Address],
    enabled: !!spender && enabled,
    onSuccess(data) {
      if (parseUnits(`${Number(allowance)}`, decimals) > BigInt(data)) {
        setIsApproved(false);
      } else {
        setIsApproved(true);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [allowance, address, spender]);

  const approve = async () => {
    try {
      setIsLoading(true);
      const tx = await walletClient?.writeContract({
        address: currency,
        abi: erc20ABI,
        functionName: 'approve' as const,
        args: [spender, parseUnits(`${Number(allowance)}`, decimals)],
      });
      if (!tx) {
        throw new Error('Approve failed');
      }
      await publicClient.waitForTransactionReceipt({ hash: tx, confirmations: 2 });

      await refetch();
      setIsLoading(false);
    } catch (e: any) {
      if (e.message.includes('reject')) {
      }
      setIsLoading(false);
    }
  };

  if (!enabled) {
    return {
      isApproved: false,
      approve: () => {},
      isLoading: false,
    };
  }

  return { isApproved, approve, isLoading: fetchAllownanceLoading || isLoading || isFetching };
}
