import { LoadingButton, Modal, Stack, Text } from '@cyberlab/uikit';
import Image from 'next/image';
import { useNotification } from './Providers/NotificationProvider';
import { useMeQuery } from '@cyber/service/pwa';
import { useAuth } from '@/hooks';
import { useState } from 'react';
import { Icon } from '@link3-pwa/icons';
type NotificationModalProps = {
  open: boolean;
  onClose: () => void;
};
export default function NotificationModal(props: NotificationModalProps) {
  const { open, onClose } = props;

  const { retrieveToken } = useNotification();
  const { isLoggedIn } = useAuth();
  const { data } = useMeQuery(undefined, { skip: !isLoggedIn });
  const userId = data?.me?.data?.id as string;

  const [loading, setLoading] = useState(false);
  return (
    <Modal 
      open={open}
      onClose={onClose} 
      sx={{ 
        '& .MuiDialogContent-root': {
          padding: '0'
        },
        '& .MuiDialog-paper': {
          border: 'none',
          backgroundColor: 'transparent'
        }
      }}
    >
      <Stack
        gap={2}
        alignItems='center'
      >
        <Stack 
          alignItems='center'
          bgcolor='bg.100'
          p={2}
          borderRadius={1}
          border='1px solid'
          borderColor='border.100'
        >
          <Image src='/assets/skeletons/notification.svg' width={189} height={125} style={{ marginTop: '-50px' }} />
          <Text variant='body2' color='text.250' mb={3} textAlign='center'>
            Enable notifications to stay updated on connections, messages, and event news!
          </Text>
          <LoadingButton
            variant='contained'
            fullWidth
            loading={loading}
            onClick={() => {
              setLoading(true);
              onClose();
              retrieveToken()
                .then((token) => {
                  const obj = { [userId]: token };
                  localStorage.fcmToken = JSON.stringify(obj);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            Enable All Notifications
          </LoadingButton>
        </Stack>
        <Icon 
          name='icon-close-circle-line' 
          size={24} 
          onClick={onClose}
        />
      </Stack>
    </Modal>
  );
}
