import Icon from '@link3-pwa/icons';

import { useAutocomplete } from '@mui/lab';
import { Avatar, Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { Text } from '..';
import Flex from '../Flex';
import Chip from './Chip';

type TOption = {
  label: string;
  value: string;
  icon?: string;
};

interface IAutoCompleteProps {
  options: TOption[];
  value?: string[];
  onChange?: (value: string[]) => void;
  placeholder?: string;
}

const Root = styled('div')(
  ({ theme }) => `
  width:100%
`,
);

const InputWrapper = styled('div')(
  ({ theme }) => `
  outline: 1px solid transparent;
  background-color: ${theme.palette.bg?.[100]};
  border-radius: ${theme.shape.borderRadius}px;
  padding: ${theme.spacing(1, 1.5)};
  display: flex;
  flex-wrap: wrap;
  row-gap:${theme.spacing(0.5)};
  
  &:hover {
    outline: 1px solid ${theme.palette.brand.main};
  }

  &.focused {
    outline: 1px solid ${theme.palette.brand.main};
  }

  & input {
    background-color: ${theme.palette.bg?.[100]};
    color:${theme.palette.text[100]} ;
    padding: 0px;
    height: 26px;
    font-size:16px;
    box-sizing: border-box;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width:100%;
  margin: 8px 0 0;
  padding:${theme.spacing(0, 1.5)};
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.bg?.[100]};
  overflow: auto;
  max-height: 250px;
  border-radius: ${theme.shape.borderRadius}px;
  z-index: 1;
`,
);

const OptionItem = (props: { listProps: any; option: any; selected: any }) => {
  const { listProps, option, selected } = props;

  return (
    <Box
      {...listProps}
      component='li'
      sx={{
        padding: '12px 0px',
        display: 'flex',
        gap: 0.5,
        borderRadius: 1,
        ':not(:last-child)': {
          borderBottom: '1px solid #595959',
        },
      }}
    >
      <Checkbox
        sx={{ padding: 0 }}
        icon={<Icon name='icon-checkbox-blank-line' size={20} color='text.250' />}
        checkedIcon={<Icon name='icon-checkbox-fill' size={20} color='brand.main' />}
        checked={selected}
      />
      <Flex gap={0.5} alignItems='center'>
        <Avatar src={option.icon} variant='circular' sx={{ width: 20, height: 20 }} />
        <Text>{option.label}</Text>
      </Flex>
    </Box>
  );
};

export default function AutoComplete(props: IAutoCompleteProps) {
  const { value: valueInProps = [], options, onChange, placeholder } = props;
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: options.filter((opt) => valueInProps.includes(opt.value)),
    multiple: true,
    options,
    getOptionLabel: (option) => option.label,
    onChange(event, value, reason, details) {
      if (reason === 'removeOption' || reason === 'selectOption' || reason === 'createOption') {
        onChange && onChange(value.map((v) => v.value));
      }
    },
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option: TOption, index: number) => (
            <Chip dispalyName={option.label} {...getTagProps({ index })} />
          ))}
          <input placeholder={placeholder || 'Search'} {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof options).map((option, index) => {
            return (
              <OptionItem
                listProps={getOptionProps({ option, index })}
                option={option}
                selected={valueInProps.includes(option.value)}
              />
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
}
