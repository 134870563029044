var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"db4dd4c7d990807c9c8ab63c5d98f456ef8d242a"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
const ENV = process.env.VERCEL_ENV || process.env.NODE_ENV;
const isProd = ENV === 'production' ? true : false;

const isIgnoreByExceptionTypeValue = (event, hint) => {
  const IgnoreTypeValues = [
    // Google Translate still breaks React after years of report.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=872770
    {
      eType: 'NotFoundError',
      eValue:
        "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      checkType: 'equals',
    },
    // We are not using Reflect.get method for now
    // caused 11k errors, by 78 users (so, sigil?)
    {
      eType: 'TypeError',
      eValue: 'Reflect.get called on non-object',
      checkType: 'startsWith',
    },
    {
      // not commonly occur in our code
      eType: 'TypeError',
      eValue: "Cannot read properties of undefined (reading 'bind')",
      checkType: 'contains',
    },
    {
      // caused by wagmi
      eType: 'Error',
      eValue: 'invalid address (argument="address", value=undefined, code=INVALID_ARGUMENT, version=address/5.7.0)',
      checkType: 'contains',
    },
    {
      eType: 'RangeError',
      eValue: 'Maximum call stack size exceeded',
      checkType: 'contains',
    },
    {
      eType: 'TypeError',
      eValue: 'UtilityScript.evaluate',
      checkType: 'stacktraceContains',
    },
    {
      eType: 'TypeError',
      eValue: 'localStorage.getItem',
      checkType: 'contains',
    },
    {
      eType: 'Error',
      eValue: 'user rejected transaction',
      checkType: 'contains',
    },
    {
      eType: 'Error',
      eValue: 'transfer amount exceeds balance',
      checkType: 'contains',
    },
    {
      eType: 'TransactionExecutionError',
      eValue: 'User rejected the request',
      checkType: 'contains',
    },
    {
      eType: 'TransactionNotFoundError',
      eValue: 'Transaction with hash',
      checkType: 'contains',
    },
    {
      eType: 'TypeError',
      eValue: '32603',
      checkType: 'contains',
    },
    {
      eType: 'TransactionExecutionError',
      eValue: 'An internal error was received',
      checkType: 'contains',
    },
    {
      eType: 'Error',
      eValue: 'closeTransport',
      checkType: 'contains',
    },
    {
      eType: 'ChunkLoadError',
      eValue: 'Loading chunk',
      checkType: 'contains',
    },
    {
      eType: 'ResourceNotFoundRpcError',
      eValue: 'wallet_requestPermissions',
      checkType: 'contains',
    },
    {
      eType: 'SwitchChainError',
      eValue: 'switch chain',
      checkType: 'contains',
    },
    {
      eType: 'Error',
      eValue: 'websocket',
      checkType: 'contains',
    },
    {
      eType: 'Error',
      eValue: 'WalletConnectModal',
      checkType: 'contains',
    },
    {
      eType: 'ContractFunctionExecutionError',
      eValue: 'the balance of the account',
      checkType: 'contains',
    },
    {
      eType: 'ResourceUnavailableRpcError',
      eValue: 'the balance of the account',
      checkType: 'contains',
    },
    {
      eType: 'ContractFunctionExecutionError',
      eValue: 'wallet_requestPermissions',
      checkType: 'contains',
    },
    {
      eType: 'Error',
      eValue: 'Cannot refetch a query that has not been started yet.',
      checkType: 'contains',
    },
  ];
  const exception = event?.exception?.values?.[0];
  const stacktraceFrames = exception?.stacktrace?.frames || [];
  return (
    exception &&
    IgnoreTypeValues.some(({ eType, eValue, checkType }) => {
      if (exception.type !== eType) return false;
      switch (checkType) {
        case 'equals':
          return exception.value === eValue;
        case 'startsWith':
          return eValue && (exception.value || '').startsWith(eValue);
        case 'contains':
          return eValue && (exception.value || '').indexOf(eValue) > -1;
        case 'stacktraceContains':
          return eValue && stacktraceFrames?.some((frame) => (frame?.function || '').indexOf(eValue) > -1);
        default:
          return false;
      }
    })
  );
};

// see: https://blog.logrocket.com/understanding-resolving-metamask-error-codes/#32601
const MetaMaskErrorCodes = [
  // Method not found
  -32601, -32602, -32002,
];

const isMetamaskError = (event, hint) => {
  const serializeData = event?.extra?.__serialized__;
  if (!serializeData) return false;

  return MetaMaskErrorCodes.some(serializeData.code);
};

const isUserRejected = (event, hint) => {
  // provider user rejected error code
  const err = hint?.originalException;
  return err && typeof err === 'object' && 'code' in err && err.code === 4001;
};
Sentry.init({
  dsn: 'https://7ef242723792f8935c82116b64c709e4@o1069278.ingest.sentry.io/4506035127451648',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: isProd,
  environment: isProd ? 'production' : 'development',
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  beforeSend(event, hint) {
    const cancelSendChecks = [isUserRejected, isIgnoreByExceptionTypeValue, isMetamaskError];
    if (cancelSendChecks.some((fn) => fn(event, hint))) {
      // console.log('ignored by code');
      return null;
    }
    // INFO: to inspect data types of sentry, log them, and return null (so that only locally)
    // console.log('event', event);
    // console.log('hint', hint);
    // return null;

    return event;
  },
  ignoreErrors: [
    'User denied transaction signature',
    'User rejected the transaction',
    'cancelled',
    'Cannot redefine property: ethereum',
    'Failed to fetch',
    "Cannot read properties of null (reading 'tronlinkParams')",
    "Cannot delete property 'aptos' of #<Window>",
  ],
  denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i],
});
