import CyberAbstract from '@cyberlab/abstract';
import { Hash } from 'viem';
import { useAccount, useWalletClient } from 'wagmi';
export default function useCyberConnect() {
  const client = useWalletClient();
  const { address } = useAccount();
  const signMessage = async (message: string) => {
    const sig = await client.data?.signMessage({
      account: address,
      message: { raw: message as Hash },
    });
    return sig;
  };
  const {
    estimateTransaction,
    sendTransaction,
    getUserOperationByHash,
    sendUserOperation,
  } = CyberAbstract(signMessage, process.env.NEXT_PUBLIC_PAYMASTER_RPC_URL!);

  return {
    estimateTransaction,
    sendTransaction,
    getUserOperationByHash,
    sendUserOperation,
  };
}
