import { actions } from '@/store';
import { TRootState } from '@/store/store';
import { formatUnits } from '@cyber-co/sdk/viem';
import { useMeQuery } from '@cyber/service/pwa';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const usePendingCredits = () => {
  const pendingCredit = useRef<
    | {
        amount: string;
        pendingAt: number;
      }
    | undefined
  >();

  const creditPollingInterval = useSelector((state: TRootState) => state.global.creditPollingInterval);
  const dispatch = useDispatch();

  const { pending, decimals } = useMeQuery(undefined, {
    pollingInterval: creditPollingInterval,
    skip: !creditPollingInterval,
    selectFromResult({ currentData }) {
      return {
        pending: currentData?.me.data?.v3Info?.credits[0].pending,
        decimals: currentData?.me.data?.v3Info?.credits[0].decimals,
      };
    },
  });

  useEffect(() => {
    if (pending !== '0' && pending) {
      pendingCredit.current = {
        amount: pending || '',
        pendingAt: Date.now(),
      };
    } else if (pending === '0' && creditPollingInterval && pendingCredit.current?.pendingAt) {
      pendingCredit.current = undefined;
      dispatch(actions.global.stopPollingCredit());
    }
  }, [pending, creditPollingInterval]);

  return {
    pendingCredit: {
      displayCredit: pending && decimals ? formatUnits(BigInt(pending), decimals) : '',
      time: pendingCredit.current?.pendingAt || 0,
    },
  };
};

export default usePendingCredits;
