import { useAuth } from '@/hooks';
import { useIsomorphicLayoutEffect } from '@cyber-co/utils';
import { useRouter } from 'next/router';

const AuthChecker = () => {
  const router = useRouter();

  const { isLoggedIn } = useAuth();

  useIsomorphicLayoutEffect(() => {
    if (!isLoggedIn) {
      router.push('/');
    }
  }, [isLoggedIn]);
  return null;
};

export default AuthChecker;
