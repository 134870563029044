import { useMessageContext } from '@/context/MessageContext';
import { ChatInfoDocument, MessageInput, SendMessageResponse_Status, useSendMessageMutation } from '@cyber/service/pwa';
import { useCallback } from 'react';
import useMessagePrice from './useMessagePrice';

export type TSendMessageParams = MessageInput;

export const useSendMessage: ({
  chatId,
}: {
  chatId: string;
}) => [updater: (p: TSendMessageParams) => Promise<{ isSuccess: boolean }>, result: any, fetchLoading: boolean] = ({
  chatId,
}) => {
  const [sendMessageCaller, result] = useSendMessageMutation();
  const { fetchLoading } = useMessagePrice({ userId: chatId });

  const sendMessage = async (input: MessageInput) => {
    return sendMessageCaller({ input }).then((data) => {
      if ('data' in data && data.data.sendMessage.status === SendMessageResponse_Status.Success) {
        return { isSuccess: true };
      }

      return { isSuccess: false };
    });
  };
  const handleSend = useCallback(
    async (messageInput: TSendMessageParams) => {
      const res = await sendMessage(messageInput);

      return res;
    },
    [sendMessage],
  );

  return [handleSend, result, fetchLoading];
};
