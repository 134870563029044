import { actions } from '@/store/reducers';
import { TRootState } from '@/store/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useTopUpModal() {
  const isTopUpModalOpen = useSelector((state: TRootState) => state.global.isTopUpOpen);
  const dispatch = useDispatch();

  const toggleTopUpModal = useCallback(
    (open = !isTopUpModalOpen) => {
      dispatch(actions.global.toggleIsTopUpOpen(open));
    },
    [dispatch, isTopUpModalOpen],
  );

  return {
    isTopUpModalOpen,
    toggleTopUpModal,
  };
}

export default useTopUpModal;
