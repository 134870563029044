import Icon from '@link3-pwa/icons';
import { useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Text } from '..';
import './ReactToastifyOverrides.css';

const ToastProvider = () => {
  const theme = useTheme();

  return (
    <ToastContainer
      position='top-center'
      autoClose={3000}
      limit={5}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      theme={theme.palette.mode}
      toastStyle={{
        borderRadius: theme.shape.borderRadius,
        top: 36,
      }}
      bodyStyle={{
        fontFamily: theme.typography.fontFamily,
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        ...theme.typography.body2,
        fontWeight: 500,
      }}
      // INFO: configured in global css overrides
      //   - color of progress bar (success, error, default gradient, etc.)
      //   - color of progress bar background light grey
      icon={ToastIcon}
      closeButton={false}
    />
  );
};

// ToastIcon is not exported from toastify
const ToastIcon = ({ theme, type }: any) => {
  const size = 24;

  switch (type) {
    case 'success':
      return <Icon name='icon-checkbox-circle-fill' size={size} color='success.main' />;
    case 'error':
      return <Icon name='icon-error-circle-fill' size={size} color='error.main' />;
    case 'warning':
      return <Icon name='icon-alert-fill' size={size} color='warning.main' />;

    case 'default':
    default:
      return <Text fontSize={size}>🦄</Text>;
  }
};

export default ToastProvider;
