import { CircularProgress, Stack } from '@mui/material';
import InfiniteBase, { Props } from 'react-infinite-scroll-component';

const Loader = () => (
  <Stack height={64} alignItems='center' justifyContent='center'>
    <CircularProgress size={32} sx={{ color: 'text.primary' }} />
  </Stack>
);
function InfiniteScroller(props: Omit<Props, 'loader'> & { loader?: Props['loader'] }) {
  const { loader, ...rest } = props;

  return <InfiniteBase loader={loader || <Loader />} {...rest} />;
}

export default InfiniteScroller;
