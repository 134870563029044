import { CyberAccount, CyberPaymaster } from '@cyberlab/cyber-account';
import { Address, Hash } from 'viem';
import { useAccount, useWalletClient } from 'wagmi';
import { SUPPORTED_NETWORKS } from '../../configs/chains';
import { cyberBundler } from './bundler';

type CyberAccounts = {
  [key: keyof typeof SUPPORTED_NETWORKS]: CyberAccount;
};

export default function useSdk({
  jwt,
  from,
  signMessage: customSignMessage,
}: {
  jwt: string;
  from?: Address;
  signMessage?: (message: Hash) => Promise<Hash>;
}) {
  const client = useWalletClient();
  const { address: _address } = useAccount();
  const address = !!from && from !== '0x' ? from : _address;

  const signMessage = async (message: string) => {
    const sig = await client.data?.signMessage({
      account: address,
      message: { raw: message as Hash },
    });
    return sig || '0x';
  };

  const cyberPaymaster = new CyberPaymaster({
    rpcUrl: process.env.NEXT_PUBLIC_CC_PAYMASTER_URL!,
    appId: process.env.NEXT_PUBLIC_CC_APPID!,
    generateJwt: async (cyberAccountAddress: Address) => jwt,
  });
  const supportedNetworkKeys = Object.keys(SUPPORTED_NETWORKS);
  const cyberAccounts: CyberAccounts | null = address
    ? supportedNetworkKeys.reduce((acc, network) => {
        return {
          ...acc,
          [network]: new CyberAccount({
            chain: {
              id: Number(network),
              testnet: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? false : true,
            },
            owner: {
              // !!important
              // If user change their eoa, estimate gas would fail
              // So when user's eoa is not the corresponding aa, we should use checkWalletStatus when user call sendTransaction
              // Usually { aa: from } = useAddresses()
              address,
              signMessage: customSignMessage || signMessage,
            },
            bundler: cyberBundler,
            paymaster: cyberPaymaster,
          }),
        };
      }, {})
    : null;
  return { cyberAccounts, cyberBundler, cyberPaymaster };
}
