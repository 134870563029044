/* mock */
// const _pendingOp = {
//   userOperation: {
//     sender: '0x33aC44FDd006A664bA953fA0E5C1b1c616634DaC',
//     nonce: '0xeb3b939d21d47598498e91baeda88b20000000000000004',
//     initCode: '0x',
//     callData:
//       '0x519454470000000000000000000000004bc54260ec3617b3f73fdb1fa22417ed109f372c00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000080000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001640fe1597f000000000000000000000000cb023b93f859ba18d12aedc712f2955cbf148c350000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000033ac44fdd006a664ba953fa0e5c1b1c616634dac000000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000c00000000000000000000000000000000000000000000000000000000000000080000000000000000000000000000000000000000000000000000000000000001b7d3a37cd8946aa7d6fdc65927a04eeecee149c5c296477ff6c942a3ccfae0b75129a81d068b58d42eec3bafd822cefb8bb4df9dceace5c2aa398b6d1dcc7e1b000000000000000000000000000000000000000000000000000000001b09da88000000000000000000000000000000000000000000000000000000000',
//     callGasLimit: '0x56ce9',
//     verificationGasLimit: '0x17d40',
//     preVerificationGas: '0xbfe0',
//     maxFeePerGas: '0x9d760f0',
//     maxPriorityFeePerGas: '0x9d760f0',
//     paymasterAndData:
//       '0xe3dc822d77f8ca7ac74c30b0dffea9fcdcaaa32100000000000000000000000000000000000000000000000000000000655307990000000000000000000000000000000000000000000000000000000000000000535b74a8765df9a86f52aa69308fc3bdaa2f11f077cb3938e034e0b3050a86a9082f650a5c1c80069687b838a2943c5e65a7d24b57b0950d0ae589ad46c763dd1c',
//     signature: '0x',
//   },
//   userOperationHash: '0x8bb9ef0cf0741b9dfcc1b51fb753ce777afb497f93077856f1b78539fe4e5dc5',
//   chainId: 420,
//   credit: '0x2ddb7',
//   status: 'PENDING_SIGN',
//   pmAndDataExpireAt: 1699939950,
//   signBeforeAt: 1699939830,
//   refundAt: 1699940070,
// };

import { optimism } from '@cyber-co/sdk/configs/chains';
import { useDispatch } from 'react-redux';
import { useAddresses } from '.';
import { useV3Sdk } from './useV3Sdk';

function useCheckPendingOperation({
  targetChainId = optimism.id,
  beforeAction,
  cb,
}: {
  targetChainId?: number;
  beforeAction?: any;
  cb: any;
}) {
  const cyberAccount = useV3Sdk({ chainId: optimism.id });
  const dispatch = useDispatch();
  const { aa } = useAddresses();
  return async (...args: any) => {
    if (aa === '0x') {
      return {};
    }
    const pendingOperations = await cyberAccount?.paymaster?.listPendingUserOperations(aa, optimism.id);

    const targetChainOP = pendingOperations?.find((op) => op.chainId === targetChainId);

    if (!!targetChainOP) {
      await cyberAccount?.paymaster?.rejectUserOperation(targetChainOP.userOperationHash, targetChainOP.chainId);
      return await cb(...args);

      // beforeAction?.();
      // dispatch(actions.global.togglePendingDrawerOpen({ open: true, pendingUserOperation: targetChainOP }));
      // return {};
    } else {
      return await cb(...args);
    }
  };
}

export default useCheckPendingOperation;
