import { useEffect, useState } from 'react';
import { IsBlob } from '../index';

type CopyFn = (item: string | Blob) => Promise<boolean>; // Return success

function useCopyToClipboard(options?: {
  expireTime?: number;
}): [boolean, CopyFn] {
  const { expireTime = 5000 } = options || {};
  const [isCopied, toggleIsCoppied] = useState<boolean>(false);

  const copy: CopyFn = async (item) => {
    if (isCopied) return false;

    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      if (IsBlob(item)) {
        await navigator.clipboard.write([
          new ClipboardItem({
            [item.type]: item,
          }),
        ]);
      } else {
        await navigator.clipboard.writeText(item);
      }
      toggleIsCoppied(true);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      return false;
    }
  };

  useEffect(() => {
    let timeout: any;
    if (isCopied && expireTime > 0) {
      timeout = setTimeout(() => {
        toggleIsCoppied(false);
      }, expireTime);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [isCopied, expireTime]);

  return [isCopied, copy];
}

export default useCopyToClipboard;
