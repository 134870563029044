import { Icon } from '@link3-pwa/icons';
import { Drawer as MuiDrawer, Stack, SwipeableDrawer, SwipeableDrawerProps, SxProps, styled } from '@mui/material';
import Text from '../Text';

import { ReactNode } from 'react';
import { Flex, LoadingButton, LoadingButtonProps } from '..';
import { ModalProps } from '../Modal';

export interface DrawerProps extends Omit<SwipeableDrawerProps, 'title' | 'onOpen' | 'onClose'> {
  title?: ReactNode;
  contentSx?: SxProps;
  buttonProps?: LoadingButtonProps;
  onClose?: ModalProps['onClose'];
  needCloseIcon?: boolean;
  noHeader?: boolean;
  titleSx?: SxProps;
}
type TitleProps = Pick<DrawerProps, 'title' | 'onClose' | 'buttonProps'> & {
  needCloseIcon?: boolean;
  onBack?: () => void;
  titleSx?: SxProps;
};

const DRAWER_SX: any = ({ spacing, palette }: any) => ({
  zIndex: 80,
  '& .MuiDrawer-paper': {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    backgroundImage: 'none',
  },
});

const ContentWrapper = styled(Stack)(({ theme: { spacing, palette } }) => ({
  width: '100%',
  height: '75vh',
  overflow: 'scroll',
  background: palette.bg?.paper,
}));

const Title = (props: TitleProps) => {
  const { title, onClose, needCloseIcon = true, onBack } = props;
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      bgcolor='bg.paper'
      p={2}
      pb={4}
      zIndex={1}
      sx={{ ...props.titleSx }}
    >
      {onBack ? (
        <Icon
          name='icon-arrow-tiny-right'
          sx={{ transform: 'rotate(-180deg) scale(1.5)' }}
          onClick={onBack}
          size={24}
        />
      ) : null}
      <Text variant='subtitle1' color='text.100' bold>
        {title}
      </Text>
      {needCloseIcon ? <Icon name='icon-close-line' onClick={onClose} /> : null}
    </Stack>
  );
};
const FullScreenTitle = (props: TitleProps) => (
  <Flex justifyContent='space-between' alignItems='center' height={56} bgcolor='bg.0' px={2} sx={{ ...props.titleSx }}>
    <Flex width={53} justifyContent='flex-start'>
      <Icon name='icon-arrow-tiny-right' size={36} sx={{ transform: 'rotate(180deg)' }} onClick={props.onClose} />
    </Flex>
    <Text variant='subtitle1' color='text.100' bold alignSelf='center'>
      {props.title}
    </Text>
    <LoadingButton variant='contained' size='tiny' {...props.buttonProps}>
      Save
    </LoadingButton>
  </Flex>
);

function Drawer(props: DrawerProps & { noTitle?: boolean; onBack?: () => void }) {
  const { children, contentSx, title, needCloseIcon, sx, noTitle, onBack, ...drawerProps } = props;

  return (
    <MuiDrawer
      disableSwipeToOpen
      anchor='bottom'
      sx={{
        ...DRAWER_SX,
        ...sx,
      }}
      bgcolor='bg.100'
      {...(drawerProps as any)}
      onClose={drawerProps.onClose || (() => {})}
      onOpen={() => {}}
      PaperProps={{ className: 'content' }}
    >
      {noTitle ? null : (
        <Title title={title} onClose={drawerProps.onClose} needCloseIcon={needCloseIcon} onBack={onBack} />
      )}
      <ContentWrapper id='drawer-scroller' px={2} pt={0} pb={3} sx={contentSx}>
        {children}
      </ContentWrapper>
    </MuiDrawer>
  );
}

function FullScreenDrawer(props: DrawerProps) {
  const { children, contentSx, title, buttonProps, noHeader, titleSx, keepMounted, ...drawerProps } = props;

  return (
    <SwipeableDrawer
      ModalProps={{ keepMounted: keepMounted || false }}
      disableSwipeToOpen
      keepMounted={false}
      anchor='right'
      sx={DRAWER_SX}
      {...(drawerProps as any)}
      onOpen={() => {}}
      PaperProps={{ className: 'content' }}
    >
      {!noHeader ? (
        <FullScreenTitle title={title} onClose={drawerProps.onClose} buttonProps={buttonProps} titleSx={titleSx} />
      ) : null}
      <ContentWrapper id='drawer-scroller' sx={{ height: 'calc(100vh - 56px)', bgcolor: 'bg.default', ...contentSx }}>
        {children}
      </ContentWrapper>
    </SwipeableDrawer>
  );
}

export { Drawer, FullScreenDrawer };
