import { actions, store } from '@/store';
import { useDispatch } from 'react-redux';

export default function useLinkEamilModal() {
  const dispatch = useDispatch();
  const open = ({ successCallback }: { successCallback: () => void }) => {
    dispatch(actions.global.toggleLinkEmailModalOpen(true));
    dispatch(actions.global.setLinkEmailSuccessCallback(successCallback));
  };
  return { open };
}
