import { useIsomorphicLayoutEffect } from '@cyber-co/utils';
import { useState } from 'react';

function useIsStandAlone() {
  const [isStandalone, toggleIsStandalone] = useState<boolean>();

  useIsomorphicLayoutEffect(() => {
    try {
      toggleIsStandalone(
        (navigator as any).standalone ||
          window.matchMedia('(display-mode: standalone)').matches ||
          process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production',
      );
    } catch (error) {
      toggleIsStandalone(false);
    }
  }, []);

  return isStandalone;
}

export default useIsStandAlone;
