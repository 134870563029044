export const truncateAddress = (address?: string | null, length: number = 10) => {
  if (!address) {
    return '';
  }
  if (address.length < length) {
    return address;
  }
  const arr = address.split('');
  const startIdx = Math.ceil(length / 2);

  arr.splice(startIdx, arr.length - length, '...');
  return arr.join('');
};
export const isSameAddress = (addr1: string | null | undefined, addr2: string | null | undefined) => {
  if (!addr1 || !addr2) {
    return false;
  }
  return addr1.toLowerCase() === addr2.toLowerCase();
};
