import { Tab as MuiTab, Tabs as MuiTabs, TabProps, TabsProps, styled, tabClasses, tabsClasses } from '@mui/material';

export const Tabs: React.FC<TabsProps> = styled(MuiTabs)(({ theme }) => ({
  backgroundColor: theme.palette.bg?.paper,
  borderBottom: '1px solid',
  borderColor: theme.palette.border?.[100],
  padding: theme.spacing(0, 2),
  [` .${tabsClasses.flexContainer}`]: {
    gap: theme.spacing(3),
    jusifyContent: 'center',
  },
}));
export const Tab: React.FC<TabProps> = styled(MuiTab)(({ theme }) => ({
  color: theme.palette.text[250],
  fontSize: 16,
  lineHeight: '21px',
  fontWeight: 400,
  padding: 0,
  minWidth: 0,
  minHeight: 0,
  height: 44,
  transition: 'all 0.1s',
  [`&.${tabClasses.selected}`]: {
    fontWeight: 700,
  },
}));
