import { useSdk } from '@cyber-co/sdk';
import { SUPPORTED_NETWORKS } from '@cyber-co/sdk/configs/chains';
import { useMeQuery } from '@cyber/service/pwa';
import { Hash } from 'viem';
import usePasskey from './passkey/usePasskey';
import useAddresses from './useAddresses';

export const useCyberAccounts = () => {
  const { jwt, turnkeyInfo } = useMeQuery(undefined, {
    selectFromResult({ data }) {
      return {
        jwt: data?.me.data?.privateInfo?.v3SdkToken || '',
        turnkeyInfo: data?.me.data?.turnkeyUserInfo,
      };
    },
  });

  const { signMessage } = usePasskey();

  const isPasskeyUser = !!turnkeyInfo;

  const { eoa: from } = useAddresses();

  const { cyberAccounts } = useSdk({
    jwt,
    from,
    // custom sign message for passkey users
    signMessage: isPasskeyUser
      ? (message: Hash) => signMessage({ message, orgId: turnkeyInfo.subOrgId, address: from })
      : undefined,
  });

  return cyberAccounts;
};

export function useV3Sdk({ chainId }: { chainId: keyof typeof SUPPORTED_NETWORKS }) {
  const cyberAccounts = useCyberAccounts();
  if (!cyberAccounts) return undefined;
  return cyberAccounts?.[chainId];
}
