import { Hash } from 'viem';

const CyberId = {
  address:
    process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
      ? ('0x484d1170d28eecda1200c32b186c66be6e0332ec' as Hash)
      : ('0xe55793f55df1f1b5037eba41881663583d4f9b24' as Hash),
  abi: [
    {
      inputs: [
        { internalType: 'string', name: 'cid', type: 'string' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'bytes32', name: 'secret', type: 'bytes32' },
        { internalType: 'bytes', name: 'middlewareData', type: 'bytes' },
      ],
      name: 'register',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'string',
          name: 'cid',
          type: 'string',
        },
      ],
      name: 'available',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'ownerOf',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
  ],
};
const CyberIdMiddleware = {
  address:
    process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
      ? ('0xe6dc2d5822e83e3bbce6c4a6847634a46b483945' as Hash)
      : ('0x40b9ad5df1cc2efacbfcc586e1b1b9e98ba72579' as Hash),
  abi: [
    {
      inputs: [{ internalType: 'string', name: 'cid', type: 'string' }],
      name: 'getPriceWei',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
  ],
};

export { CyberId, CyberIdMiddleware };
