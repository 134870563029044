import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import Flex from '../Flex';
import Modal from '../Modal';

export interface IConfirmModal {
  title: string;
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => Promise<void>;
}

function ConfirmModal(props: PropsWithChildren<IConfirmModal>) {
  const { title, children, open = false } = props;
  const { onClose, onConfirm } = props;

  const [isLoading, toggleIsLoading] = useState(false);

  const handleClose = () => {
    if (isLoading) return;

    onClose?.();
  };

  const handleConfirm = async () => {
    if (onConfirm) {
      toggleIsLoading(true);
      await onConfirm();
      toggleIsLoading(false);
      onClose?.();
    }
  };
  return (
    <Modal
      onClose={handleClose}
      width={360}
      noCloseBtn
      open={open}
      PaperProps={{
        sx: { p: 1.5 },
      }}
      titleProps={{
        children: title,
        variant: 'body1',
        fontWeight: 700,
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Stack mt={2} textAlign='center'>
        {children}
      </Stack>

      <Flex gap={1.5} mt={4}>
        <Button disabled={isLoading} onClick={handleClose} fullWidth variant='outlined' color='primary'>
          Cancel
        </Button>

        <LoadingButton loading={isLoading} onClick={handleConfirm} fullWidth variant='contained' color='error'>
          Confirm
        </LoadingButton>
      </Flex>
    </Modal>
  );
}

export default ConfirmModal;
