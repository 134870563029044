import { ReactNode, useEffect, useState } from 'react';

import { format, getMonth, getYear } from 'date-fns';
import Picker from 'react-mobile-picker';
import { Button, Drawer, Flex, Input, Stack, Text } from '..';
import { ModalProps } from '../Modal';

const currentYear = getYear(new Date());
const currentMonth = getMonth(new Date());

const options = {
  year: Array.from(new Array(30)).map((_, i) => String(currentYear - i)),
  month: Array.from(new Array(12)).map((_, i) => String(i)),
};

interface IMobileDatePickerrProps {
  value?: Date | null;
  onChange?: (newValue: Date | null) => void;
  error?: boolean;
  variant?: 'standard' | 'present';
}

type TPickerValue = {
  month: string;
  year: string;
};

export default function MobileDatePicker(props: IMobileDatePickerrProps) {
  const { value, onChange, error, variant = 'standard' } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Input
        readOnly
        error={error}
        value={value ? format(value, 'MMM/yyyy') : variant === 'present' ? 'Present' : ''}
        autoFocus={false}
        placeholder='MM/YYYY'
        onClick={(e) => {
          setIsOpen(true);
        }}
      />
      <PickerDrawer
        variant={variant}
        open={isOpen}
        value={value}
        onChange={onChange}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}

const renderColumn = ({
  name,
  options,
  renderLabel = (v: string) => v,
}: {
  options: string[];
  name: string;
  renderLabel?: (v: string) => ReactNode;
}) => {
  return (
    <Picker.Column key={name} name={name}>
      {options.map((option) => (
        <Picker.Item key={option} value={option}>
          {({ selected }) => (
            <Text
              bold={selected}
              sx={{ userSelect: 'none' }}
              // variant='body2'
            >
              {renderLabel(option)}
            </Text>
          )}
        </Picker.Item>
      ))}
    </Picker.Column>
  );
};

const PickerDrawer = (props: IMobileDatePickerrProps & Pick<ModalProps, 'onClose' | 'open'>) => {
  const { open, onClose, value, onChange, variant } = props;

  const getInitialData = () => {
    if (value) {
      return {
        month: String(getMonth(value)),
        year: String(getYear(value)),
      };
    } else if (variant === 'present') {
      return {
        month: String(currentMonth),
        year: 'Present',
      };
    }

    return {
      month: String(currentMonth),
      year: String(currentYear),
    };
  };

  const [pickerValue, setPickerValue] = useState<TPickerValue>(getInitialData);

  const handleChange = ({ year, month }: TPickerValue) => {
    setPickerValue({
      year,
      month: year === 'Present' ? String(currentMonth) : month,
    });
  };

  const handleConfirm = () => {
    const { year, month } = pickerValue;

    const isPresent = year === 'Present';

    const date = isPresent ? null : new Date(Number(year), Number(month));
    onChange && onChange(date);
    onClose && onClose();
  };

  useEffect(() => {
    if (!open) {
      return () => {
        setPickerValue(getInitialData());
      };
    }
  }, [open]);

  return (
    <Drawer
      disableSwipeToOpen
      draggable={false}
      // ModalProps={{ keepMounted: false }}
      title='Choose Date'
      open={open}
      onClose={onClose}
      contentSx={{ height: 216 + 36, pb: 0 }}
    >
      <Stack mt={-3}>
        <Picker value={pickerValue} onChange={handleChange}>
          {renderColumn({ options: variant === 'present' ? ['Present', ...options.year] : options.year, name: 'year' })}
          {renderColumn({
            options: options.month,
            name: 'month',
            renderLabel(v) {
              return Number(v) + 1;
            },
          })}
        </Picker>

        <Flex gap={1} justifyContent='flex-end'>
          <Button onClick={onClose} fullWidth variant='outlined'>
            CANCEL
          </Button>
          <Button onClick={handleConfirm} fullWidth variant='contained'>
            CONFIRM
          </Button>
        </Flex>
      </Stack>
    </Drawer>
  );
};
