import { ThemeOptions, TypeBackground } from '@mui/material';
import { COLORS } from './system/designSystem';

interface CustomBackground extends TypeBackground {
  0: string;
  100: string;
  200: string;
  300: string;
}

declare module '@mui/material/styles' {
  interface PaletteOptions {
    cardBg: string;
    fill1: string;
    twitter: string;
    bg?: Partial<CustomBackground>;
  }
  interface Palette {
    bg?: Partial<CustomBackground>;
    cardBg: string;
    twitter: string;
    fill1: string;
  }
}
export const lightPalette: ThemeOptions['palette'] = {
  mode: 'light',
  primary: {
    main: '#000',
    light: '#000',
    dark: '#000',
    disabled: COLORS.grey[400],
    contrastText: '#FFF',
  },
  secondary: {
    main: COLORS.grey[200]!,
    dark: COLORS.grey[200],
    light: COLORS.grey[200],
    disabled: COLORS.grey[100],
    contrastText: '#353849',
  },
  brand: {
    main: COLORS.brand[500]!,
    light: COLORS.brand[500]!,
    dark: COLORS.brand[500]!,
    disabled: COLORS.brand[200]!,
    contrastText: '#fff',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  text: {
    primary: '#000',
    secondary: COLORS.grey[800],
    third: COLORS.grey[600],
    100: COLORS.grey[800],
    200: COLORS.grey[600],
    250: COLORS.grey[500],
    300: COLORS.grey[400],
    1000: '#fff',
    disabled: COLORS.grey[300],
    link: '#5551FF',
  },
  error: {
    main: COLORS.red[500]!,
    light: COLORS.red[300]!,
    dark: COLORS.red[700]!,
    contrastText: 'grey.50',
  },
  warning: {
    main: COLORS.yellow[400]!,
    light: COLORS.yellow[400]!,
    dark: COLORS.yellow[400]!,
    contrastText: 'grey.50',
  },
  success: {
    main: COLORS.green[400]!,
    light: COLORS.green[400]!,
    dark: COLORS.green[400]!,
    contrastText: 'grey.50',
  },
  background: {
    paper: '#ffffff',
    default: COLORS.grey[100]!,
  },
  bg: {
    paper: '#ffffff',
    default: COLORS.grey[100]!,
    0: '#fff',
    100: COLORS.grey[50]!,
    200: COLORS.grey[100]!,
    300: COLORS.grey[200]!,
  },
  border: {
    0: COLORS.grey[50]!,
    100: COLORS.grey[100]!,
    200: COLORS.grey[200]!,
    300: COLORS.grey[300]!,
    400: COLORS.grey[400]!,
  },
  action: {
    disabledBackground: 'none',
    disabled: '#9e9fa8',
  },
  grey: COLORS.grey,
  yellow: COLORS.yellow,
  divider: '#ebeef3',
  cardBg: '#FAFAFA',
  fill1: '#f2f4f8',
  twitter: '#0FA9FF',
};
