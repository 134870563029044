import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '../graphqlBaseQuery';

export const api = createApi({
  reducerPath: 'easAPi',
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.NEXT_PUBLIC_EAS_ENDPOINT!,
  }),
  endpoints: () => ({}),
});
