import { DiscordOauth } from '@/types';

const DISCORD_ADD_BOT_CLIENT_ID = process.env.NEXT_PUBLIC_DISCORD_ADD_BOT_CLIENT_ID;
const DISCORD_LOGIN_CLIENT_ID = process.env.NEXT_PUBLIC_DISCORD_LOGIN_CLIENT_ID;
export const getUserDiscordOauthUrl = ({
  state,
  allowManageEvent,
}: {
  state: DiscordOauth;
  allowManageEvent?: boolean;
}) => {
  const redirectUri = typeof window !== 'undefined' ? window?.origin + 'redirect' : '';

  const _clientId = getClientId(state);

  const baseOauthUrl = `https://discord.com/api/oauth2/authorize?client_id=${_clientId}&redirect_uri=${redirectUri}&state=${state}&response_type=code`;

  if (state === DiscordOauth.DISCORD_BOT_UPDATE) {
    return allowManageEvent
      ? baseOauthUrl + '&permissions=8611955729&&scope=bot%20guilds'
      : baseOauthUrl + '&permissions=1025&&scope=bot%20guilds';
  }

  if (state === DiscordOauth.DISCORD_BOT) {
    return allowManageEvent
      ? baseOauthUrl + '&permissions=8611955729&&scope=bot%20guilds'
      : baseOauthUrl + '&permissions=1025&&scope=bot%20guilds';
  }

  return baseOauthUrl + '&scope=identify%20email';
};

const getClientId = (state: DiscordOauth) => {
  return state === DiscordOauth.DISCORD_BOT || state === DiscordOauth.DISCORD_BOT_UPDATE
    ? DISCORD_ADD_BOT_CLIENT_ID
    : DISCORD_LOGIN_CLIENT_ID;
};
