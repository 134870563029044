import { useEffect, useState } from 'react';

export function useIsAndroid() {
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsAndroid(userAgent.includes('android'));
  }, []);

  return isAndroid;
}

export function useIsIOS() {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  return isIOS;
}

export function useIOSHeaderOffset() {
  const [offset, setOffset] = useState(0);
  const isIOS = useIsIOS();
  useEffect(() => {
    if (isIOS) {
      setOffset(44);
    }
  }, [isIOS]);

  return offset;
}
