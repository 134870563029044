import {
  Chain,
  arbitrumGoerli,
  arbitrum as arbitrumMainnet,
  base as baseMainnet,
  bsc as bscMainnet,
  bscTestnet,
  goerli,
  lineaTestnet,
  mainnet as ethereumMainnet,
  optimism as opMainnet,
  polygon as polygonMainnet,
  polygonMumbai,
  scroll as scrollMainnet,
  scrollSepolia,
  optimismSepolia,
  baseSepolia,
} from 'viem/chains';

const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

export const lineaMainnet = {
  id: 59144,
  name: 'Linea',
  network: 'linea mainnet',
  rpcUrls: {
    infura: { http: ['https://rpc.linea.build'] },
    public: { http: ['https://rpc.linea.build'] },
    default: {
      http: [`https://linea-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`],
    },
  },
  blockExplorers: {
    default: {
      name: 'BlockScout',
      url: 'https://explorer.linea.build',
    },
  },
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
};

export const opBnbMainnet = {
  id: 204,
  name: 'opBNB',
  network: 'opBNB',
  rpcUrls: {
    public: { http: ['https://opbnb-mainnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3'] },
    default: {
      http: [`https://opbnb-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
    },
  },
  blockExplorers: {
    default: {
      name: 'BlockScout',
      url: 'https://opbnbscan.com',
    },
  },
  nativeCurrency: bscMainnet.nativeCurrency,
};

export const opBnbTestnet = {
  id: 5611,
  name: 'opBNB Testnet',
  network: 'opBNB testnet',
  rpcUrls: {
    public: { http: ['https://opbnb-testnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3'] },
    default: {
      http: [`https://opbnb-testnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3`],
    },
  },
  blockExplorers: {
    default: {
      name: 'BlockScout',
      url: 'https://testnet.opbnbscan.com',
    },
  },
  nativeCurrency: bscTestnet.nativeCurrency,
};

export const chainRpcs = {
  [ethereumMainnet.id]: [`https://eth-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
  [bscMainnet.id]: [`https://bsc-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
  [bscTestnet.id]: [`https://bsc-testnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
  [goerli.id]: [`https://eth-goerli.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
  [polygonMainnet.id]: [`https://polygon-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
  [polygonMumbai.id]: [`https://polygon-mumbai.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`],
  [lineaMainnet.id]: [`https://linea-mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`],
  [lineaTestnet.id]: [`https://linea-goerli.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`],
  [opMainnet.id]: [`https://opt-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
  [optimismSepolia.id]: [`https://optimism-sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`],

  [arbitrumMainnet.id]: [
    `https://open-platform.nodereal.io/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}/arbitrum-nitro/`,
  ],
  [arbitrumGoerli.id]: [`https://arbitrum-goerli.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`],
  [opBnbMainnet.id]: [`https://opbnb-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODEREAL_API_KEY}`],
  [opBnbTestnet.id]: [`https://opbnb-testnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3`],
};

// Testnet not supported
export const mainnet = extendChain(isProduction ? ethereumMainnet : goerli, {
  logo: 'ethereum',
  cmcTokenId: '1027',
});

export const linea = extendChain(isProduction ? lineaMainnet : lineaTestnet, {
  logo: 'linea',
  cmcTokenId: '1027',
});

export const bsc = extendChain(isProduction ? bscMainnet : bscTestnet, {
  logo: 'bnb',
  cmcTokenId: '1839',
});
export const optimism = extendChain(isProduction ? opMainnet : optimismSepolia, {
  logo: 'optimism',
  cmcTokenId: '1027',
});
export const polygon = extendChain(isProduction ? polygonMainnet : polygonMumbai, {
  logo: 'polygon',
  cmcTokenId: '3890',
});
export const base = extendChain(isProduction ? baseMainnet : baseMainnet, {
  logo: 'base',
  cmcTokenId: '1027',
});

export const arbitrum = extendChain(isProduction ? arbitrumMainnet : arbitrumGoerli, {
  logo: 'arbitrum',
  cmcTokenId: '1027',
});

export const opBnb = extendChain(isProduction ? opBnbMainnet : opBnbTestnet, {
  logo: 'bnb',
  cmcTokenId: '1839',
});

export const scroll = extendChain(isProduction ? scrollMainnet : scrollSepolia, {
  logo: 'scroll',
  cmcTokenId: '1027',
});

// !important CyberAccountSDK must add corresponding chain, or it will cause application error
export const SUPPORTED_NETWORKS = {
  [polygon.id]: polygon,
  [linea.id]: linea,
  [base.id]: base,
  [optimism.id]: optimism,
  [arbitrum.id]: arbitrum,
  [opBnb.id]: opBnb,
  [scroll.id]: scroll,
  [mainnet.id]: mainnet,
};

export const TOPUP_SUPPORTED_NETWORKS = {
  ...SUPPORTED_NETWORKS,
  [bsc.id]: bsc,
  [opBnb.id]: opBnb,
};

export const LINK3_SUPPROTED_CHAINS = {
  [linea.id]: linea,
  [polygon.id]: polygon,
  [bsc.id]: bsc,
  [arbitrum.id]: arbitrum,
  [base.id]: base,
  [optimism.id]: optimism,
  [opBnb.id]: opBnb,
  [scroll.id]: scroll,
};

export const PWA_SUPPORTED_CHAINS = {
  [optimism.id]: optimism,
};
function extendChain(chain: Chain, options: { logo: string; cmcTokenId: string }) {
  const logoPath = '/assets/chains/' + options.logo + '.svg';
  return {
    ...chain,
    logo: logoPath,
    cmcTokenUrl: `https://s2.coinmarketcap.com/static/img/coins/128x128/${options.cmcTokenId}.png`,
  };
}
