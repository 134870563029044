import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { DocumentNode } from 'graphql';
import { ClientError, GraphQLClient } from 'graphql-request';
import { LS_ACCESS_TOKEN_KEY } from './constants';
type P = Parameters<GraphQLClient['request']>;

export const isIPFSEndPoints = (document: string | undefined = '') =>
  document.includes('mutation pinFile') || document.includes('mutation pinJson');

export type Document = P[0];

export const graphqlRequestBaseQuery = (
  options: { url: string; requestHeaders?: any; accessTokenKey?: string } | { client: GraphQLClient },
): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  Pick<ClientError, 'name' | 'message' | 'stack'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> => {
  const client = 'client' in options ? options.client : new GraphQLClient(options.url);
  const accessTokenKey =
    ('accessTokenKey' in options ? options.accessTokenKey : LS_ACCESS_TOKEN_KEY) || LS_ACCESS_TOKEN_KEY;

  return async ({ document, variables }, { getState, dispatch }) => {
    try {
      let ls;
      if ('requestHeaders' in options) {
        client.setHeaders(options.requestHeaders);
      }
      if (typeof window !== 'undefined') {
        ls = window.localStorage;
      }
      if (ls && ls[accessTokenKey]) {
        client.setHeader('Authorization', ls[accessTokenKey]);
      } else {
        client.setHeader('Authorization', '');
      }

      client.setHeader('Platform', 'PWA');

      // !important remove it,因为会暴露adminToken,
      // the token of pinFile & pinJSON is customize
      // if (isIPFSEndPoints(document.toString())) {
      // client.setHeader('Authorization', IPFS_TOKEN);
      // }

      const res = await client.request(document, variables);

      if (
        res.login &&
        res.login.data?.privateInfo &&
        res.login.data?.privateInfo.accessToken &&
        ls &&
        ls[accessTokenKey]
      ) {
        client.setHeader('Authorization', ls[accessTokenKey]);
      }
      const key = Object.keys(res)[0];
      const result = res[key];
      if ('url' in options && options?.url.includes('wallet') && res.me === null) {
        return {
          error: 'NOT_LOGIN',
        };
      }
      if (result.status === 'NOT_LOGIN') {
        return {
          error: 'NOT_LOGIN',
        };
      }

      if (result.status === 'FAILED') {
        return {
          error: result.message,
        };
      }

      return { data: res, meta: {} };
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error;
        return { error: { name, message, stack }, meta: { request, response } };
      }
      throw error;
    }
  };
};
