import { ThemeOptions } from '@mui/material';
import { COLORS } from './system/designSystem';

export const darkPalette: ThemeOptions['palette'] = {
  mode: 'dark',
  primary: {
    main: '#fff',
    light: '#ffffff',
    dark: '#ffffffCC',
    disabled: COLORS.grey[600],
    contrastText: '#000',
  },
  secondary: {
    main: COLORS.grey[800]!,
    dark: COLORS.grey[900],
    light: COLORS.grey[700],
    disabled: COLORS.grey[900],
    contrastText: '#fff',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  brand: {
    main: COLORS.brand[500]!,
    light: COLORS.brand[200]!,
    dark: COLORS.brand[500]!,
    disabled: COLORS.brand[200]!,
    contrastText: '#fff',
  },
  text: {
    primary: COLORS.grey[200],
    100: COLORS.grey[200],
    200: COLORS.grey[400],
    250: COLORS.grey[500],
    300: COLORS.grey[600],
    1000: '#000',
    disabled: COLORS.grey[700],
    link: '#5551FF',
  },
  error: {
    main: COLORS.red[500]!,
    light: COLORS.red[300]!,
    dark: COLORS.red[700]!,
    contrastText: '#fff',
  },
  success: {
    main: COLORS.green[400]!,
    light: COLORS.green[400]!,
    dark: COLORS.green[400]!,
  },
  warning: {
    main: COLORS.yellow[400]!,
    light: COLORS.yellow[400]!,
    dark: COLORS.yellow[400]!,
  },
  background: {
    paper: COLORS.grey[950]!,
    default: '#000',
  },
  bg: {
    paper: COLORS.grey[950]!,
    default: '#000',

    0: COLORS.grey[950]!,
    100: COLORS.grey[900]!,
    200: COLORS.grey[800]!,
    300: COLORS.grey[700]!,
  },
  border: {
    0: COLORS.grey[900]!,
    100: COLORS.grey[800]!,
    200: COLORS.grey[700]!,
    300: COLORS.grey[600]!,
    400: COLORS.grey[500]!,
  },
  action: {
    disabledBackground: 'none',
    disabled: '#d9d9d9',
  },
  grey: COLORS.grey,
  yellow: COLORS.yellow,
  divider: COLORS.grey[800]!,
  cardBg: '#1D1D1D',
  fill1: '#f2f4f8', // TODO: dark mode color
  twitter: '#0FA9FF',
};
