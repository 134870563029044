import { parseEther } from 'viem';
import { Address, useContractWrite, useWaitForTransaction } from 'wagmi';
import { TokenReceiver } from '../contracts';
// 目前应该都是parseEther就行
type UseTopupProps = {
  cyberAccount: Address;
  chainId?: number;
};
export default function useTopup(props: UseTopupProps) {
  const { cyberAccount, chainId } = props;

  const {
    write,
    writeAsync,
    isLoading,
    isSuccess,
    isError,
    error,
    data: writeContractResult,
  } = useContractWrite({
    address: TokenReceiver.address as Address,
    abi: TokenReceiver.abi,
    functionName: 'depositTo',
    chainId: chainId,
  });

  const {
    data: txData,
    isError: isGettingTxError,
    isLoading: isGettingTx,
    isSuccess: isGettingTxSuccess,
  } = useWaitForTransaction({
    hash: writeContractResult?.hash,
    enabled: !!writeContractResult?.hash,
  });

  const topup = async (amount: string) => {
    return await writeAsync({
      args: [cyberAccount],
      value: parseEther(amount),
    });
  };

  return {
    topup,
    isLoading,
    isSuccess,
    isError,
    data: writeContractResult,
    error,
    tx: {
      data: txData,
      isError: isGettingTxError,
      isLoading: isGettingTx,
      isSuccess: isGettingTxSuccess,
    },
  };
}
