import { isMobile } from './platform';

export const fixTwitterLink = ({ id, handle }: { id?: string; handle?: string }) => {
  if (!id && !handle) return '';

  // use deeplink on mobile
  if (isMobile()) {
    return id ? `twitter://user?user_id=${id}` : `twitter://user?screen_name=${handle}`;
  }

  return id ? `https://twitter.com/i/user/${id}` : `https://twitter.com/${handle}`;
};

export const shareToTwitter = (text: string) => {
  // use deeplink on mobile
  if (isMobile()) {
    return window.open(`twitter://post?message=${encodeURIComponent(text)}`);
  }

  return window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`);
};

export const isValidTwitterHandle = (str: string) => {
  const reg = new RegExp(/(\w){1,15}$/);

  return reg.test(str);
};
