import { ThemeProvider } from '@cyberlab/uikit';
import { PropsWithChildren } from 'react';

const ThemeWrapper = ({ children }: PropsWithChildren<{}>) => {
  // const currentTheme = useSelector((state: TRootState) => state.global.theme);
  // const dispatch = useDispatch();

  // 暂时隐藏lightmode
  // useEffect(() => {
  //   const theme = window.localStorage.getItem(LS_CURRENT_THEME_KEY) as 'light' | 'dark';
  //   dispatch(actions.global.setGlobalTheme(theme || 'dark'));
  // }, []);

  return <ThemeProvider theme='dark'>{children}</ThemeProvider>;
};

export default ThemeWrapper;
