import { useClient } from '@cyber-co/utils';
import { InputBase, InputProps, SxProps, inputBaseClasses, inputClasses, styled } from '@mui/material';
import { forwardRef, useState } from 'react';
import Flex from '../Flex';
import Text from '../Text';
export const StyledInput: React.FC<InputProps> = styled(InputBase, {
  name: 'InputBase',
  shouldForwardProp: (prop) => prop !== 'WrapperSx',
})(({ theme }) => {
  return {
    height: 42,
    backgroundColor: theme.palette.bg?.[100],
    fontSize: 16,
    fontWeight: 400,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 1.5),
    input: {
      padding: 0,
      height: '26px',
    },
    [`&.${inputClasses.disabled}`]: {
      backgroundColor: theme.palette.bg![100],
    },
    [`&.${inputClasses.focused}`]: {
      outline: `1px solid ${theme.palette.brand.main}`,
    },
    [` .${inputBaseClasses.inputAdornedStart}`]: {
      marginLeft: theme.spacing(1),
    },
    [` .${inputBaseClasses.inputAdornedEnd}`]: {
      marginRight: theme.spacing(1),
      color: 'text.250',
    },
    [`&.${inputBaseClasses.error}`]: {
      outline: `1px solid ${theme.palette.error.main}`,
    },
    [` .${inputBaseClasses.disabled}`]: {
      WebkitTextFillColor: `${theme.palette.text[250]} !important`,
    },
    [`&.${inputBaseClasses.multiline}`]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
  };
});
type CInputProps = InputProps & {
  limit?: number;
  WrapperSx?: SxProps;
};

function Input(props: CInputProps, ref: any) {
  const { fullWidth = true, limit, startAdornment, endAdornment, sx, onChange, ...restProps } = props;
  const [value, setValue] = useState(restProps.value || '');
  const isClient = useClient();

  return isClient ? (
    <StyledInput
      fullWidth={fullWidth}
      onChange={(e) => {
        setValue(e.target.value.slice(0, limit));
        onChange?.(e);
      }}
      ref={ref}
      startAdornment={
        startAdornment ? (
          typeof startAdornment === 'string' ? (
            <Text color='text.200' className='input-start'>
              {startAdornment}
            </Text>
          ) : (
            <Flex alignItems='center'>{startAdornment}</Flex>
          )
        ) : null
      }
      endAdornment={
        endAdornment ? (
          typeof endAdornment === 'string' ? (
            <Text color='text.250'>{endAdornment}</Text>
          ) : (
            <>{endAdornment}</>
          )
        ) : null
      }
      value={value}
      sx={sx}
      {...restProps}
    />
  ) : null;
}
export default forwardRef(Input);
