export const genShadows = () => {
  return {
    knob1: '0px 4px 8px 0px #666D8014, 0px 1px 1px 0px #666D8014',
    knob2: '0px 2px 4px 0px #666D801F, 0px 1px 1px 0px #666D801F',
    drop1: '0px 6px 4px 0px #666D800F, 0px 1px 1px 0px #806D8014',
    drop2: '0px 6px 8px 0px #666D803D, box-shadow: 0px 1px 1px 0px #6D6D7A1F',
    shadow1: '0px 8px 32px 0px #666D801F',
    shadow2: '0px 8px 32px 0px #666D8052',
  };
};
