import { Color, CssBaseline } from '@mui/material';
import type { ThemeOptions } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { lightPalette } from './colors';
import { genComponents } from './components';
import { darkPalette } from './darkColors';
import { genShadows } from './shadows';
import { breakpoints } from './system/breakpoints';
import { typography } from './typography';

const LS_LOCAL_THEME_KEY = 'theme';

const lsGet = (key: string) => {
  if (typeof window === 'undefined') return;
  return window.localStorage?.getItem(key);
};

const lsSet = (key: string, value: string) => {
  if (typeof window === 'undefined') return;
  return window.localStorage?.setItem(key, value);
};

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    disabled?: string;
  }
  interface PaletteColor {
    disabled?: string;
  }
  interface TypeText {
    third?: string;
    link?: string;
    [100]?: string;
    [200]?: string;
    [250]?: string;
    [300]?: string;
    [1000]?: string;
  }
  interface Palette {
    text: TypeText;
    border?: Partial<Color>;
    brand: {
      [key: string]: string;
    };
    yellow?: Partial<Color>;
    cardBg: string;
    fill1: string;
  }
  interface PaletteOptions {
    text?: Partial<TypeText>;
    border?: Partial<Color>;
    brand: {
      [key: string]: string;
    };
    yellow?: Partial<Color>;
    cardBg: string;
    fill1: string;
  }
  interface Theme {
    cyberShadow: {
      knob1: string;
      knob2: string;
      drop1: string;
      drop2: string;
      shadow1: string;
      shadow2: string;
    };
  }
  interface ThemeOptions {
    cyberShadow: {
      knob1: string;
      knob2: string;
      drop1: string;
      drop2: string;
      shadow1: string;
      shadow2: string;
    };
  }
}

const spacing: ThemeOptions['spacing'] = 8;
const shape: ThemeOptions['shape'] = {
  borderRadius: 4,
};

type ThemeValue = 'light' | 'dark';

export const genTheme = (theme: ThemeValue = 'dark') => {
  const palette = theme === 'light' ? lightPalette : darkPalette;
  const components = genComponents(palette);
  const shadows = genShadows();
  return createTheme({
    components,
    breakpoints,
    palette,
    spacing,
    shape,
    typography,
    cyberShadow: shadows,
  });
};

export const lightTheme = genTheme('light');
export const darkTheme = genTheme('dark');

export const ThemeContext = createContext({
  theme: 'light',
  setTheme: (theme: ThemeValue) => {},
});

export const ThemeProvider = ({ children, theme }: PropsWithChildren<{ theme: 'light' | 'dark' }>) => {
  // const [theme, setTheme] = useState('dark');

  // useEffect(() => {
  //   setTheme(lsGet(LS_LOCAL_THEME_KEY) || 'dark');
  // }, []);

  const setThemeFn = useCallback((newTheme: ThemeValue) => {
    // setTheme(newTheme);
    lsSet(LS_LOCAL_THEME_KEY, newTheme);
  }, []);

  const muiTheme: any = theme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeContext.Provider value={{ theme, setTheme: setThemeFn }}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export function numberToHex(number: number) {
  const hex = number.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function getHexOpacity(opacity: number) {
  return numberToHex(Math.floor(255 * opacity));
}
