// TOFIX: 应该都要用new RegExp
export const VALIDATE_LINK_REG =
  /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;

export const VALIDATE_BASE64_REG = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

export const VALIDATE_EMAIL = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
);

export const VALIDATE_LINK_HANDLE = /^[0-9a-z_]{1,20}$/;

export const VALIDATE_TWITTER_SPACE = /^(https:\/\/)?(?:twitter|x).com\/i\/spaces\/1[a-zA-Z]{12}(\?s=20)?$/;
