import { OrgProfile, PerProfile, ProfileInPostCardFragment, ProfileType, VerificationStatus } from '@cyber/service/pwa';

export type ProfileAuthor = {
  profileType?: ProfileType;
  handle?: string;
  picture?: string | null;
  displayName?: string;
  isFollowing?: boolean;
  verified?: boolean;
  id?: string;
};

export const getAuthorFromProfile = (profile: ProfileInPostCardFragment): ProfileAuthor => {
  if (profile?.__typename === 'PerProfile') {
    return {
      id: profile?.id,
      handle: profile?.handle,
      // renamed in fragment: UnionDisplayNameFragment and UnionProfilePictureFragment
      picture: profile?.personalProfilePicture?.picture,
      displayName: profile?.personalDisplayName?.displayName,
      isFollowing: profile?.isFollowing,
      // INFO: Author will not be user anymore
      verified: true,
      profileType: profile?.type,
    };
  } else if (profile?.__typename === 'OrgProfile') {
    // profile.type === ProfileType.Organization
    return {
      id: profile?.id,
      handle: profile?.handle,
      picture: profile?.profilePicture,
      displayName: profile?.displayName,
      isFollowing: profile?.isFollowing,
      verified: (profile as any)?.organization?.verification === VerificationStatus.Verified,
      profileType: profile?.type,
    };
  }
  return {};
};
