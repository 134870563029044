import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import reducer, { middleware } from './reducers';

// for csr
export const store = configureStore({
  reducer,
  middleware,
});

// for ssr
export const makeStore = () => store;

export type AppStore = ReturnType<typeof makeStore>;
export type TRootState = ReturnType<AppStore['getState']>;
export type TAppDispatch = AppStore['dispatch'];

export const wrapper = createWrapper<AppStore>(makeStore, {
  // debug: process.env.NEXT_PUBLIC_VERCEL_ENV == 'production' ? false : true,
});
