export function isChrome() {
  return /Chrome/i.test(navigator?.userAgent);
}
export function isPWA() {
  return window?.matchMedia('(display-mode: standalone)').matches;
}
export function isMobile() {
  return typeof window !== 'undefined'
    ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent)
    : false;
}
export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator?.userAgent);
}
export function isAndroid() {
  return /Android/i.test(navigator?.userAgent);
}
