export { default as addCyberToMetaMask } from './addCyberToMetaMask';
export * from './address';
export * from './base64url';
export * from './date';
export * from './image';
export * from './link';
export * from './markdown';
export * from './nft';
export * from './number';
export * from './platform';
export * from './profile';
export * from './regex';
export * from './twitter';
export * from './wallet';
export * from './browser';

export const IsBlob = (value: any): value is Blob => value instanceof Blob;
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
export function isUUID(str?: string): boolean {
  if (!str) {
    return false;
  }
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}
