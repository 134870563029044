import { useEffect, useState } from 'react';

export function useTabVisibility() {
  const [isActive, toggleIsActive] = useState(true);
  useEffect(() => {
    let hidden: string, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    if (
      typeof document.addEventListener === 'undefined' ||
      // @ts-ignore
      typeof document[hidden] === 'undefined' ||
      !visibilityChange
    ) {
      return;
    }

    document.addEventListener(visibilityChange, () => {
      // @ts-ignore
      if (document[hidden]) {
        toggleIsActive(false);
      } else {
        toggleIsActive(true);
      }
    });
  }, []);

  return isActive;
}
