import useIsStandAlone from '@/hooks/useIsStandAlone';
import InstallPage from '@/views/InstallPage';
import DownPage from '@/views/InstallPage/DownPage';
import { PropsWithChildren } from 'react';

function StandaloneChecker(props: PropsWithChildren<{}>) {
  const isStandAlone = useIsStandAlone();

  if (typeof isStandAlone !== 'boolean') return null;

  return <DownPage />;
  if (!isStandAlone) return <InstallPage />;

  return <>{props.children}</>;
}

export default StandaloneChecker;
