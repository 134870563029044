import { Box, Flex, styled } from '@cyberlab/uikit';

export type TabType = 'ios' | 'android';

export const Tab = styled(Box, { shouldForwardProp: (p) => p !== 'isActive' })<{ isActive: boolean }>(
  ({ isActive, theme: { shape, spacing, palette } }) => ({
    borderRadius: shape.borderRadius,
    padding: spacing(1, 1.5),
    backgroundColor: isActive ? '#fff' : 'transparent',
    fontWeight: 700,
    fontSize: 16,
    color: isActive ? palette.text[1000] : palette.text[250],
  }),
);

export const Tabs = styled(Flex)(({ theme: { shape, spacing, palette } }) => ({
  flexDirection: 'row',
  gap: spacing(0.5),
  padding: spacing(0.5),
  borderRadius: shape.borderRadius,
  border: '1px solid',
  borderColor: palette.border?.[100],
}));
