import { SxProps, Typography, TypographyProps } from '@mui/material';
import { forwardRef } from 'react';

export interface TextProps extends TypographyProps {
  lines?: number;
  medium?: boolean;
  bold?: boolean;
  needUnderline?: boolean;
}

const Text = forwardRef(function TextComp({ lines, medium, bold, children, needUnderline, ...props }: TextProps, ref) {
  const { sx, ...typoProps } = props;
  const clampSx: SxProps<any> | undefined = lines
    ? {
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: lines,
        WebkitBoxOrient: 'vertical',
      }
    : undefined;
  const cursorSx: SxProps | undefined = typoProps.onClick ? { cursor: 'pointer' } : undefined;
  const needUnderlineSx = needUnderline ? { '&:hover': { cursor: 'pointer', textDecoration: 'underline' } } : undefined;
  const allSx: SxProps<any> = {
    fontWeight: !!bold ? 700 : !!medium ? 500 : typoProps.fontWeight || 400,
    transition: 'all .3s ease',
    ...sx,
    ...clampSx,
    ...cursorSx,
    ...needUnderlineSx,
  };

  return (
    <Typography {...typoProps} sx={allSx}>
      {children}
    </Typography>
  );
});

export default Text;
