import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const LinkEmailModal = dynamic(() => import('../LinkEmailModal'), { ssr: false });
const LinkTwitterModal = dynamic(() => import('../LinkTwitterModal'), { ssr: false });
const SignModal = dynamic(() => import('@/views/Modals/SignModal'), { ssr: false });

function GlobalModals() {
  const { isReady } = useRouter();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if (isReady) {
      setIsClient(true);
    }
  }, [isReady]);

  if (!isClient) return null;

  return (
    <>
      <LinkEmailModal />
      <LinkTwitterModal />
      <SignModal />
      {/* <PendingDrawer /> */}
    </>
  );
}

export default GlobalModals;
