import { Hex, formatUnits, hexToBigInt } from 'viem';

export const formatNumber = (number?: number | string | null, maximumFractionDigits = 8) => {
  if (!number) return '0';

  if (typeof number === 'string' && !isNaN(Number(number))) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits,
    }).format(Number(number));
  } else if (typeof number === 'string') {
    return '0';
  }

  return new Intl.NumberFormat('en-US', { maximumFractionDigits }).format(number as number);
};

export const formatCMCPercent = (value: number, maximumFractionDigits = 2) => {
  if (!value) return '-';
  return value.toFixed(maximumFractionDigits);
};
export const formatCMCTokenPrice = (value: number) => {
  if (typeof value !== 'number') return '-';
  if (value >= 1) {
    return value.toFixed(2);
  }
  if (value < 1) {
    return value.toFixed(5);
  }
};

export const compactNumber = (num: number | undefined, maximumFractionDigits = 0) => {
  if (!num) {
    return 0;
  }
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits,
  }).format(num);
};

export const formatGasCredit = (gasHex: Hex | undefined | null) => {
  if (!gasHex) return 0;
  return Number(formatUnits(hexToBigInt(gasHex), 6));
};

export const formatPaidValueToDollar = (value: bigint | string | undefined, decimal: number | undefined) => {
  const _value = typeof value === 'bigint' ? value : BigInt(value || '0');
  return formatNumber(formatUnits(_value, decimal || 0), 2);
};
