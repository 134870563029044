import { Avatar, AvatarProps, ListItem as MListItem, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Text } from '..';

export interface IListItemProps {
  avatarProps?: AvatarProps;
  avatarSize?: number;
  title?: string;
  subtitle?: string;
  content?: ReactNode;
}

function ListItem(props: IListItemProps) {
  const { avatarProps, avatarSize = 40, title, subtitle, content } = props;
  return (
    <MListItem
      sx={{
        px: 3,
        gap: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar {...avatarProps} sx={{ width: avatarSize, height: avatarSize }} />
      <Stack width={'100%'} gap={2 / 8} py={1.5} borderBottom='1px solid' borderColor='border.100'>
        <Text variant='body1' color='text.100'>
          {title}
        </Text>
        {!subtitle ? null : (
          <Text variant='caption' color='text.250'>
            {subtitle}
          </Text>
        )}
        {content}
      </Stack>
    </MListItem>
  );
}

export default ListItem;
