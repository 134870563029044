import { isSafari } from './browser';

export const convertCanvasToWebP = (canvas: HTMLCanvasElement): { contentType: string; content: string } => {
  const contentType = 'image/webp';
  const base64: string = canvas.toDataURL(contentType) || '';

  return {
    contentType,
    content: base64.replace('data:image/webp;base64,', ''),
  };
};
export const base64toBlob = (b64Data: string, contentType: string = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const setImageBlob = (canvas: HTMLCanvasElement, cb: any): void => {
  if (!cb) return;

  if (isSafari()) {
    return canvas.toBlob((blob) => {
      cb(blob);
    });
  }

  const content = convertCanvasToWebP(canvas);
  const webpBlob = base64toBlob(content.content);
  cb(webpBlob);
};

export async function getFileMd5Hash16(file: File): Promise<string> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      const buffer = reader.result as ArrayBuffer;
      if (buffer) {
        const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
        const hash16 = hashHex.substr(8, 16);
        resolve(hash16);
      } else {
        reject(new Error('Failed to read file'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };

    reader.readAsArrayBuffer(file);
  });
}

/**
 * @info 后端线上转用 zerion 所以 cmcTokenId 不存在，stg 仍然使用这个方法
 * 在 wallet 应用中请优先使用 TokenImage
 * 64x64 is too small for some screen
 */
export const getCMCTokenIconSrc = (tokenId?: string | number) =>
  tokenId ? `https://s2.coinmarketcap.com/static/img/coins/128x128/${tokenId}.png` : null;

export const fixPinataUrl = (cid: string) => `${process.env.NEXT_PUBLIC_IPFS_GATEWAY}${cid}`;

export const isValidBase64 = (text?: string): boolean => {
  if (typeof text !== 'string') return false;

  return text.startsWith('data:image');
};

export const fixNFTIPFSURL = (imgurl: string | null | undefined) => {
  if (!imgurl) return '';

  if (imgurl.startsWith('http') || imgurl.startsWith('https') || isValidBase64(imgurl)) return fixIPFSUrl(imgurl);

  return `${process.env.NEXT_PUBLIC_IPFS_GATEWAY}${imgurl}`;
};

export const fixIPFSUrl = (url?: string) => {
  return (
    url
      ?.replace('https://cyberconnect.mypinata.cloud/ipfs/', process.env.NEXT_PUBLIC_IPFS_GATEWAY!)
      .replace('ipfs://', process.env.NEXT_PUBLIC_IPFS_GATEWAY!) || ''
  );
};
