import {
  add,
  compareAsc,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  endOfDay,
  endOfTomorrow,
  format,
  formatDistanceStrict,
  formatRelative,
  fromUnixTime,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getUnixTime,
  getYear,
  isAfter,
  secondsToHours,
  secondsToMinutes,
  set,
} from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

// this is how inconsistent API wastes your time
const Checks = [
  { key: 'years', label: 'year', fn: differenceInYears, getter: getYear, setKey: 'year' },
  { key: 'months', label: 'month', fn: differenceInMonths, getter: getMonth, setKey: 'month' },
  { key: 'days', label: 'day', fn: differenceInDays, getter: getDate, setKey: 'date' },
  { key: 'hours', label: 'hour', fn: differenceInHours, getter: getHours, setKey: 'hours' },
  { key: 'minutes', label: 'minute', fn: differenceInMinutes, getter: getMinutes, setKey: 'minutes' },
  { key: 'seconds', label: 'second', fn: differenceInSeconds, getter: getSeconds, setKey: 'seconds' },
];

export const dateRemaining = (d1: Date, d2: Date, stopsAt = 'seconds'): string | null => {
  if (!d1 || !d2) return null;

  const compared = compareAsc(d1, d2);

  if (compared === 0) return '';

  const results = [];
  let [earlier, later] = compared === -1 ? [d1, d2] : [d2, d1];

  if (stopsAt) {
    const clones: any = {};
    for (const { key, setKey, getter } of Checks) {
      // clones[key] = later.get;
      clones[setKey] = getter(later);

      if (key === stopsAt) break;
    }

    const closestLater = set(new Date(earlier), clones);
    later = add(closestLater, { [stopsAt]: compareAsc(closestLater, later) === -1 ? 1 : 0 });
  }

  for (const { key, label, fn } of Checks) {
    let diff = fn(later, earlier);

    if (diff) {
      earlier = add(earlier, { [key]: diff });
      results.push(`${diff} ${label}${diff > 1 ? 's' : ''}`);
    }

    if (stopsAt === key) break;
  }

  return results.length ? `${results.join(' ')} left` : '';
};
export const getDefaultTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone || 'Atlantic/Azores';

export function getMilliseconds(hour: number | undefined, minutes: number | undefined): number {
  if (!hour && !minutes) {
    return 0;
  }

  if (!hour && minutes) {
    return minutes * 60 * 1000;
  }

  if (hour && !minutes) {
    return hour * 60 * 60 * 1000;
  }

  return hour! * 60 * 60 * 1000 + minutes! * 60 * 1000;
}

export function getTimezone() {
  const timezone = getDefaultTimeZone();
  return { area: timezone.split('/')[0], location: timezone.split('/')[1] };
}

export function getUnixTimestamp(date: Date | number) {
  return getUnixTime(date);
}

export function getEndTimestamp(startTime: Date | number, hours: number, minutes: number) {
  return add(startTime, {
    hours,
    minutes,
  });
}

export function getDateFromUnixTimestamp(unixTimestamp: number): Date {
  return fromUnixTime(unixTimestamp);
}

export function getDurationFromUnixTimestamp(
  endTimestamp: number,
  startTimestamp: number,
): { durationHour: number; durationMin: number } {
  const minutes = differenceInMinutes(fromUnixTime(endTimestamp), fromUnixTime(startTimestamp));
  return {
    durationHour: Math.floor(minutes / 60),
    durationMin: minutes % 60,
  };
}

export const transformDuration = (second: number) => {
  if (second < 3600) {
    return secondsToMinutes(second) + 'm';
  }

  const hours = secondsToHours(second);
  const minutes = secondsToMinutes(second - hours * 3600);

  return hours + 'h' + (minutes ? minutes + 'm' : '');
};

export const getRelativeToNow = (timestamp: number) => {
  const now = Date.now();
  if (isAfter(timestamp, endOfTomorrow())) {
    return formatDistanceStrict(endOfDay(timestamp), now, { addSuffix: true }) + ' at ' + format(timestamp, 'p');
  }

  return formatRelative(timestamp, now);
};

export const getEventStartTime = (timestamp: number) => {
  const date = getDateFromUnixTimestamp(timestamp);
  return format(date, "MMMM d 'at' h:mm a");
};

export function roundNewDate(value: Date) {
  const year = value.getFullYear();
  const month = value.getMonth();
  const day = value.getDate();

  const hour = value.getHours();
  const min = value.getMinutes();
  return new Date(year, month, day, hour, min >= 30 ? 30 : 0);
}
/**
 *
 * @param time  ms
 * @param timezone
 */
export const getUTCOBasedTime = (time: number, timezone: string | undefined): number => {
  const defaultTz = getDefaultTimeZone();

  const totalOffsetSeconds = (getTimezoneOffset(defaultTz) - getTimezoneOffset(timezone || defaultTz)) / 1000;
  return roundNewDate(add(time, { seconds: totalOffsetSeconds })).getTime();
};

export const getTimeWithOffset = (time: number, timezone: string | undefined): Date => {
  const defaultTz = getDefaultTimeZone();

  const totalOffsetSeconds = -(getTimezoneOffset(defaultTz) - getTimezoneOffset(timezone || defaultTz)) / 1000;
  return add(time, { seconds: totalOffsetSeconds });
};

export const formatEventTime = (timestamp: string | number, ended: boolean) => {
  if (ended) {
    return format(Number(timestamp) * 1000, 'Pp');
  }
  const time = getRelativeToNow(Number(timestamp) * 1000);
  return time.charAt(0).toUpperCase() + time.slice(1);
};
