import { Color } from '@mui/material';

declare module '@mui/material' {
  interface Color {
    0: string;
    950: string;
    1000: string;
  }
}

export const COLORS: {
  grey: Partial<Color>;
  brand: Partial<Color>;
  red: Partial<Color>;
  green: Partial<Color>;
  yellow: Partial<Color>;
  gradient: {
    1: string;
    2: string;
  };
  linearCardBg: string;
} = {
  grey: {
    0: '#FFFFFF',
    50: '#FAFAFA',
    100: '#f5f5f5',
    200: '#f0f0f0',
    300: '#d9d9d9',
    400: '#bfbfbf',
    500: '#8c8c8c',
    600: '#595959',
    700: '#434343',
    800: '#262626',
    900: '#1F1F1F',
    950: '#141414',
    1000: '#000000',
  },
  brand: {
    200: '#A6F4D7',
    500: '#0db48c',
    800: '#05604F',
    900: '#054F42',
  },
  red: {
    300: '#EF9E97',
    500: '#E45E52',
    700: '#8E3A32',
  },
  green: {
    50: '#E9F8DD',
    100: '#D3F2BA',
    200: '#BCEB98',
    300: '#A6E575',
    400: '#90DE53',
    500: '#73B242',
    600: '#568532',
    700: '#3A5921',
    800: '#1D2C11',
    900: '#0E1608',
  },
  yellow: {
    50: '#FFF7DC',
    100: '#FFEFB8',
    200: '#FEE795',
    300: '#FEDF71',
    400: '#FED74E',
    500: '#CBAC3E',
    600: '#98812F',
    700: '#66561F',
    800: '#332B10',
    900: '#191608',
  },
  gradient: {
    1: 'linear-gradient(91deg, #0026FF 0%, #0F6 62.52%, #F00 97.92%)',
    2: 'linear-gradient(90deg, #3A51FC 0%, #FF000C 68.75%, #FFAE00 100%)',
  },
  linearCardBg: 'linear-gradient(98deg, #303030 0.43%, #252525 95.59%)',
};
