import { ConnectStatus } from '@cyber/service/pwa';
import { createSlice } from '@reduxjs/toolkit';
type PersonalConnectStatus = {
  [key: string]: ConnectStatus | undefined;
};
type OrgFollowStatus = {
  [key: string]: boolean | undefined;
};
const initialState: { personalConnectStatus: PersonalConnectStatus; orgFollowStatus: OrgFollowStatus } = {
  personalConnectStatus: {},
  orgFollowStatus: {},
};

const connectSlice = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    updatePersonalConnectStatus(state, action: { payload: { profileId: string; connectStauts: ConnectStatus } }) {
      state.personalConnectStatus[action.payload.profileId] = action.payload.connectStauts;
    },
    updateOrgFollowStatus(state, action: { payload: { profileId: string; isFollowing: boolean } }) {
      state.orgFollowStatus[action.payload.profileId] = action.payload.isFollowing;
    },
  },
});

export const { updatePersonalConnectStatus, updateOrgFollowStatus } = connectSlice.actions;

export default connectSlice.reducer;
