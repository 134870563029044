import { Hex, hexToNumber, slice } from 'viem';

import { WalletClient } from 'wagmi';

export type PermitSignature = {
  r: Hex;
  s: Hex;
  v: number;
};

export interface ISignPermitProps {
  /** Address of the token to approve */
  contractAddress: Hex;
  /** Name of the token to approve.
   * Corresponds to the `name` method on the ERC-20 contract. Please note this must match exactly byte-for-byte */
  erc20Name: string;
  /** Owner of the tokens. Usually the currently connected address. */
  ownerAddress: Hex;
  /** Address to grant allowance to */
  spenderAddress: Hex;
  /** Expiration of this approval, in SECONDS */
  deadline: bigint;
  /** Numerical chainId of the token contract */
  chainId: number;
  /** Defaults to 1. Some tokens need a different version, check the [PERMIT INFORMATION](https://github.com/vacekj/wagmi-permit/blob/main/PERMIT.md) for more information */
  permitVersion?: string;
  /** Permit nonce for the specific address and token contract. You can get the nonce from the `nonces` method on the token contract. */
  nonce: bigint;
  /** Amount to approve */
  value: bigint;
}

export const signPermit = async (walletClient: WalletClient, props: ISignPermitProps): Promise<PermitSignature> => {
  const { erc20Name, permitVersion, chainId, contractAddress, ownerAddress, spenderAddress, value, deadline, nonce } =
    props;

  const types = {
    Permit: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ],
  };

  const domainData = {
    name: erc20Name,
    version: permitVersion ?? '1',
    chainId: chainId,
    verifyingContract: contractAddress,
  };

  const message = {
    owner: ownerAddress,
    spender: spenderAddress,
    value,
    nonce,
    deadline,
  };

  const signature = await walletClient.signTypedData({
    account: ownerAddress,
    message,
    domain: domainData,
    primaryType: 'Permit',
    types,
  });

  const [r, s, v] = [slice(signature, 0, 32), slice(signature, 32, 64), slice(signature, 64, 65)];

  return { r, s, v: hexToNumber(v) };
};
