import { Hash } from 'viem';

const CyberEngine = {
  address: process.env.NEXT_PUBLIC_CYBER_ENGINE_ADDRESS as Hash,
  abi: [
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'id', type: 'uint256' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
            {
              internalType: 'enum DataTypes.Category',
              name: 'category',
              type: 'uint8',
            },
          ],
          internalType: 'struct DataTypes.CollectParams',
          name: 'params',
          type: 'tuple',
        },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'collect',
      outputs: [
        {
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
};

export default CyberEngine;
