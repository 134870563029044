import { LS_LINK3_SEARCH_HISTORY } from '@/configs/constants';
import { createSlice } from '@reduxjs/toolkit';

const SEPERATOR = ', ';
const MAX_HISTORY_COUNT = 5;

const initialState: {
  keywords: string[];
  lastSearchedKeyword?: string;
} = { keywords: [], lastSearchedKeyword: undefined };

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    readKeywordsFromLocalstorage: (state) => {
      const storagedKeyword = window.localStorage.getItem(LS_LINK3_SEARCH_HISTORY) || '';

      if (storagedKeyword) {
        state.keywords = storagedKeyword.split(SEPERATOR);
      }
    },
    storeKeywordsToLocalstorage: (state) => {
      window.localStorage.setItem(LS_LINK3_SEARCH_HISTORY, state.keywords.join(SEPERATOR));
    },

    updateKeywords: (
      state,
      action: {
        payload: {
          keyword: string;
          operator: 'add' | 'remove';
        };
      },
    ) => {
      const { keyword, operator } = action.payload;

      if (operator === 'remove') {
        state.keywords = state.keywords.filter((k) => k !== keyword);
      } else {
        if (!state.keywords.includes(keyword)) {
          const newKeywords = state.keywords;

          newKeywords.unshift(keyword);
          state.keywords = newKeywords.slice(0, MAX_HISTORY_COUNT);
          state.lastSearchedKeyword = keyword;
        }
      }
    },

    updateLastSearchedKeyword(
      state,
      action: {
        payload: {
          keyword: string;
        };
      },
    ) {
      state.lastSearchedKeyword = action.payload.keyword;
    },
    clearSearchedKeyword(state) {
      state.keywords = [];
      window.localStorage.removeItem(LS_LINK3_SEARCH_HISTORY);
    },
  },
});

export const {
  clearSearchedKeyword,
  updateLastSearchedKeyword,
  updateKeywords,
  readKeywordsFromLocalstorage,
  storeKeywordsToLocalstorage,
} = searchSlice.actions;

export default searchSlice.reducer;
