import { Stack, Text } from '@cyberlab/uikit';
import Image from 'next/image';
import Link from 'next/link';

function DownPage() {
  return (
    <Stack
      gap={3}
      width='100vw'
      height='100vh'
      alignItems='center'
      justifyContent='flex-start'
      pt={'35vh'}
      px={2}
      sx={{
        backgroundImage: 'url(/assets/bg/login-bg.png)',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Image src='/assets/logos/link3-logo-full-brand.svg' width={150} height={40} />
      <Text textAlign='center' variant='subtitle1'>
        Thank your for testing out the brand new Link3 mobile app. As we continue to polish the app and strive for an
        even better user experience, the Link3 mobile app will be temporarily unavailable. Stay tuned for the official
        launch!
      </Text>

      <Text textAlign='center' variant='subtitle1'>
        If you have any questions, i.e. withdrawing, please contact{' '}
        <Link href='mailto:jamesdai@cyberconnect.me'>
          <Text display='inline' color='text.link' variant='subtitle1'>
            jamesdai@cyberconnect.me
          </Text>
        </Link>
      </Text>
    </Stack>
  );
}

export default DownPage;
