import { CyberEngine } from '@cyber-co/sdk/contracts';
import { EstimateUserOperationReturn } from '@cyber-co/sdk/cyberAccount';
import { encodeFunctionData, hexToBigInt } from '@cyber-co/sdk/viem';
import { toast } from '@cyberlab/uikit';
import useAddresses from './useAddresses';
import { useV3Sdk } from './useV3Sdk';

export type CollectParams = {
  issuerAddress?: string | null;
  tokenId?: number | null;
  data?: string | null;
  estimatedGas?: EstimateUserOperationReturn;
};
export default function useClaimW3st({ chainId }: { chainId: number }) {
  const cyberAccount = useV3Sdk({ chainId });
  const { aa } = useAddresses();

  const displayErrorMessage = (message: string) => {
    toast({ message, variant: 'error' });
  };

  const estimateGas = cyberAccount
    ? async ({ issuerAddress, tokenId, data }: CollectParams) => {
        const callData = encodeFunctionData({
          abi: CyberEngine.abi,
          functionName: 'collect',
          args: [{ account: issuerAddress, id: tokenId, amount: 1, category: 2, to: cyberAccount?.address }, data],
        });
        try {
          const estimatedGas = await cyberAccount?.estimateTransactionWithPaymaster({
            from: aa,
            to: CyberEngine.address,
            data: callData,
            value: 0n,
          });
          return estimatedGas;
        } catch (e) {
          return undefined;
        }
      }
    : undefined;

  const claim = async ({ issuerAddress, tokenId, data, estimatedGas }: CollectParams) => {
    if (!cyberAccount?.address || !issuerAddress || tokenId === undefined || !data) {
      displayErrorMessage('Collect failed, please try again.');
      return;
    }

    const callData = encodeFunctionData({
      abi: CyberEngine.abi,
      functionName: 'collect',
      args: [{ account: issuerAddress, id: tokenId, amount: 1, category: 2, to: cyberAccount?.address }, data],
    });
    try {
      const sendTransactionData = {
        to: CyberEngine.address,
        data: callData,
        value: 0n,
        maxFeePerGas: hexToBigInt(estimatedGas?.maxFeePerGas || '0x0'),
        maxPriorityFeePerGas: hexToBigInt(estimatedGas?.maxPriorityFeePerGas || '0x0'),
      };
      const sendRes = await cyberAccount?.sendTransaction(sendTransactionData);
      return sendRes;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return { claimV3W3st: claim, estimateGas };
}
