import { List } from '@mui/material';
import { ReactNode } from 'react';
import ListItem, { IListItemProps } from './ListItem';

type TListData = {
  title: string;
  subtitle?: string;
  content?: ReactNode;
  avatar?: string;
};
interface IListViewProps {
  data: TListData[];
  renderList: (data: TListData) => IListItemProps;
}

function ListView(props: IListViewProps) {
  const { data, renderList } = props;
  return (
    <List sx={{ width: '100%' }}>
      {data.map((d) => (
        <ListItem key={d.title} {...renderList(d)} />
      ))}
    </List>
  );
}

export default ListView;
