import { optimism } from '@cyber-co/sdk/configs/chains';
import { Address } from '@cyber-co/sdk/wagmi';

const CyberVaultContract = {
  address: process.env.NEXT_PUBLIC_CYBER_VAULT_CONTRACT_ADDRESS! as Address,
  abi: [
    {
      type: 'function',
      name: 'depositERC20',
      inputs: [
        { name: 'from', type: 'address', internalType: 'address' },
        { name: 'to', type: 'address', internalType: 'address' },
        { name: 'currency', type: 'address', internalType: 'address' },
        { name: 'amount', type: 'uint256', internalType: 'uint256' },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
  ],
};

export const depositTokenAddress = process.env.NEXT_PUBLIC_USDT_ADDRESS! as Address;

export default CyberVaultContract;
