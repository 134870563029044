import {
  pwaClient as cyberApi,
  easClient as easApi,
  galaxyClient as galaxyApi,
  snapshotClient as snapshotApi,
} from '@cyber/service';
import { api as thirdPartyApi } from '@cyber/service/api';
import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import globalReducer, * as globalActions from './global';
import messageReducer, * as messageActions from './message';
import searchReducer, * as searchActions from './search';
import connectReducer, * as connectActions from './connect';

export const actions = {
  global: globalActions,
  message: messageActions,
  search: searchActions,
  connect: connectActions,
};

// this MW is used to update link3 token
export const rtkQueryEnhancement: Middleware =
  ({ dispatch, getState }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    try {
      if (action.meta?.requestStatus === 'fulfilled') {
        if (action.payload?.me?.data?.privateInfo?.accessToken) {
          dispatch(globalActions.updateAccessToken({ value: action.payload.me.data.privateInfo.accessToken }));
        }
      }

      if (isRejectedWithValue(action)) {
        if (action.payload === 'NOT_LOGIN') {
          dispatch(globalActions.deleteAccessToken());
        }
      }

      return next(action);
    } catch (error) {
      console.log('🚀 ~ file: index.ts:37 ~ error:', error, action);
    }
  };

export const middleware = (getDafaultMW: any) =>
  getDafaultMW({ serializableCheck: false }).concat(
    cyberApi.middleware,
    thirdPartyApi.middleware,
    galaxyApi.middleware,
    snapshotApi.middleware,
    easApi.middleware,
    rtkQueryEnhancement,
  );

const reducer = {
  global: globalReducer,
  message: messageReducer,
  search: searchReducer,
  connect: connectReducer,
  [cyberApi.reducerPath]: cyberApi.reducer,
  [thirdPartyApi.reducerPath]: thirdPartyApi.reducer,
  [galaxyApi.reducerPath]: galaxyApi.reducer,
  [snapshotApi.reducerPath]: snapshotApi.reducer,
  [easApi.reducerPath]: easApi.reducer,
};

export default reducer;
