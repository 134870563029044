import { Event } from '@cyber/service/pwa';
import format from 'date-fns/format';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

/**
 * @return
 * {'Sep 9, Friday': Event[],'Sep 10, Saturday': Event[]}
 */

type GroupedEvents = Record<string, Event[]>;
export const groupEventsByDay = (events: Event[]): GroupedEvents => {
  const res = events.reduce((acc: Record<string, any>, cur) => {
    const key = format(Number(cur.startTimestamp) * 1000, 'MMM d, EEEE');
    if (key in acc) {
      return {
        ...acc,
        [key]: [...acc[key], cur],
      };
    }

    return { ...acc, [key]: [cur] };
  }, {});

  return res;
};

function customizer(objValue: Event[], srcValue: Event[]) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export const mergeGroupedEventsByDay = (oldEvents: GroupedEvents, newEvents: GroupedEvents) =>
  mergeWith(oldEvents, newEvents, customizer);

// 年终大会需要特殊修改的链接
export const SPECIAL_EVENTS = [
  { eventId: 'foCuvr', quoteLink: 'https://twitter.com/CyberConnectHQ/status/1614952058117062658' },
  { eventId: 'ALx4Rf', quoteLink: 'https://twitter.com/CyberConnectHQ/status/1614911940748546048' },
  { eventId: '2Pi7sL', quoteLink: 'https://twitter.com/CyberConnectHQ/status/1614901006609055744' },
  { eventId: 'YW80m3', quoteLink: 'https://twitter.com/CyberConnectHQ/status/1615249554169008128' },
  { eventId: 'rAIJhF', quoteLink: 'https://twitter.com/CyberConnectHQ/status/1615252005366431744' },
  { eventId: 'IEbwEg', quoteLink: 'https://twitter.com/CyberConnectHQ/status/1615254168863744001' },
  { eventId: 'SAj8kK', quoteLink: 'https://twitter.com/CyberConnectHQ/status/1615258165364559876' },
  {
    eventId: 'RJQapi',
    quoteLink: 'https://twitter.com/CyberConnectHQ/status/1614402090193006594',
  },
  {
    eventId: 'gbeKbd',
    quoteLink: 'https://twitter.com/CyberConnectHQ/status/1627771703848849422',
    youtubeLink: 'https://www.youtube.com/watch?v=d3_rYSpNDoQ',
  },
];

export const checkIsSpecialEvent = (eventId: string | undefined) => {
  if (!eventId) {
    return false;
  } else {
    return !!SPECIAL_EVENTS.find((event) => event.eventId === eventId)?.eventId;
  }
};

export const getSpecialEventQuoteLink = (eventId: string) => {
  return SPECIAL_EVENTS.find((event) => event.eventId === eventId)?.quoteLink || '#';
};

export const getSpecialEventLocation = (eventId: string) => {
  return SPECIAL_EVENTS.find((event) => event.eventId === eventId)?.youtubeLink || 'https://youtube.com';
};
