import { useNotification } from '@/component/Providers/NotificationProvider';
import { actions } from '@/store';
import { deleteAccessToken, updateAccessToken } from '@/store/reducers/global';
import { TRootState } from '@/store/store';
import { useAccount, useConnect, useDisconnect, useNetwork, useSignMessage } from '@cyber-co/sdk/wagmi';
import { isMobile } from '@cyber-co/utils';
import { api, useLoginMutation, useNonceMutation } from '@cyber/service/pwa';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { SiweMessage } from 'siwe';

const getSiweMessage = ({
  statement,
  nonce,
  address,
  chainId,
}: {
  statement?: string;
  nonce: string;
  address: string;
  chainId: number;
}) =>
  new SiweMessage({
    domain: window.location.host,
    address,
    statement,
    uri: window.location.origin,
    version: '1',
    chainId,
    nonce,
    expirationTime: new Date(new Date().getTime() + 60 * 60 * 24 * 14 * 1000).toISOString(),
    notBefore: new Date().toISOString(),
  });

export default function useAuth() {
  const isLoggedIn = useSelector((state: TRootState) => !!state.global.accessToken);
  const router = useRouter();
  const dispatch = useDispatch();
  const { connectAsync, isLoading, connectors } = useConnect();
  const { disconnectAsync, reset } = useDisconnect();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { unregisterToken } = useNotification();
  const { signMessageAsync, isLoading: signLoading } = useSignMessage();
  const [getNonce, { isLoading: getNonceLoading }] = useNonceMutation();
  const [verify, { isLoading: verifyLoading }] = useLoginMutation();

  const changeAccessToken = (token: string) => {
    dispatch(updateAccessToken({ value: token }));
  };
  const login = async () => {
    let connector;

    if (isMobile() && process.env.NEXT_PUBLIC_VERCEL_ENV !== 'development') {
      connector = connectors.find((c) => c.name.toLowerCase() === 'walletconnect');
    } else {
      connector = connectors.find((c) => c.name.toLowerCase() === 'metamask');
    }

    await connectAsync({ connector });
  };

  const signAndVerify = async () => {
    const nonceData = await getNonce({
      address: address || '0x',
    });
    if ('data' in nonceData && 'nonce' in nonceData.data) {
      const message = getSiweMessage({
        nonce: nonceData.data.nonce.data,
        address: address || '0x',
        chainId: chain?.id || 1,
      }).prepareMessage();
      const signature = await signMessageAsync({ message });
      const res = await verify({
        signedMessage: message,
        token: '',
        address: address,
        chainId: chain?.id || 1,
        signature,
        isEIP1271: false,
      });
      if ('data' in res) {
        const accessToken = res.data.login.data?.privateInfo?.accessToken!;
        await dispatch(api.util.resetApiState());
        dispatch(actions.global.updateAccessToken({ value: accessToken }));
      }
    }
  };

  const logout = async () => {
    try {
      await disconnectAsync();
      reset();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(deleteAccessToken());
      dispatch(api.util.resetApiState());
      router.push('/');
      unregisterToken();
    }
    // window.localStorage.clear();
  };
  return {
    isLoggedIn,
    login: { login, isLoading },
    logout,
    changeAccessToken,
    signAndVerify: { signAndVerify, isLoading: signLoading || getNonceLoading || verifyLoading },
  };
}
