import { store } from '@/store';
import { Toast } from '@cyberlab/uikit';
import { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import AuthChecker from './AuthChecker';
import GlobalModals from './GlobalModals';
import NotificationProvider from './NotificationProvider';
import StandaloneChecker from './StandaloneChecker';
import ThemeWrapper from './ThemeWrapper';
import Web3Provider from './Web3Provider';

const Providers = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ReduxProvider store={store}>
      <Web3Provider>
        <ThemeWrapper>
          <NotificationProvider>
            <StandaloneChecker>{children}</StandaloneChecker>
            <AuthChecker />
            <Toast />
            <GlobalModals />
          </NotificationProvider>
        </ThemeWrapper>
      </Web3Provider>
    </ReduxProvider>
  );
};

export default Providers;
