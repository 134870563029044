import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyBA0JCkmJO1pw9wBOjgbLdUvk4BhBTuXV8',
  authDomain: 'link3-5ae83.firebaseapp.com',
  projectId: 'link3-5ae83',
  storageBucket: 'link3-5ae83.appspot.com',
  messagingSenderId: '649113681334',
  appId: '1:649113681334:web:7984cde08dd7585cf11517',
  measurementId: 'G-YQ90NY1EW7',
};

export const vapid = 'BM4I8Y118ClfH13IbaCYhdPFTEiQHHHjuB2nm7vzoXPVCaqPnM8QNjv9Ju-KZct2UVOcMhZod6ekmOtT7PYqZ7Y';
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
