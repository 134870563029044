import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0,0,0,.1)',
    backdropFilter: 'blur(10px)',
    marginTop: '0 !important',
    padding: theme.spacing(1, 1.5),
    border: '1px solid',
    borderColor: theme.palette.border?.[100],
    marginBottom: 5,
  },
}));
export default StyledTooltip;
