import { PendingUserOperation } from '@cyber-co/sdk/cyberAccount';
import { LS_ACCESS_TOKEN_KEY } from '@cyber/service';
import { createSlice } from '@reduxjs/toolkit';

export type THomepageCollapseStatus = {
  individual?: boolean;
  organization?: boolean;
  post?: boolean;
  event?: boolean;
};

type TGlobalTwitterModalStatus = {
  isOpen: boolean;
  type: 0 | 1; // 0: new user link twitter,1: old user re-auth
};
const initialState: {
  accessToken: string | undefined | null;
  [key: string]: any;
  isAdmin: boolean | null;
  globalLinkTwitterModalStatus: TGlobalTwitterModalStatus;
  needRefetchEvents: {
    landing: boolean;
    explore: boolean;
  };
  isTopUpOpen: boolean;
  isDepositModalOpen: boolean;
  isWithdrawModalOpen: boolean;
  theme: 'light' | 'dark';
  creditPollingInterval: 0 | 3000;
  linkEmailModalOpen: boolean;
  linkEmailSuccessCallback: (() => void) | null;
  headerSearchMaskOpen: boolean;
  pendingDrawerOpen: boolean;
  pendingUserOperation: PendingUserOperation | null;
  unreadCount: number;
  myConnectionTab: 0 | 1 | 2;
  homepageCollapseStatus: THomepageCollapseStatus;
  signModalStatus: {
    isOpen: boolean;
    sign?: () => Promise<void>;
  };
  homeTaskAutoplay: boolean;
} = {
  accessToken: null,
  isAdmin: null,
  globalLinkTwitterModalStatus: {
    type: 0,
    isOpen: false,
  },
  needRefetchEvents: {
    landing: false,
    explore: false,
  },
  isTopUpOpen: false,
  signModalStatus: {
    isOpen: false,
    sign: undefined,
  },
  isDepositModalOpen: false,
  isWithdrawModalOpen: false,
  theme: 'dark',
  creditPollingInterval: 0,
  linkEmailModalOpen: false,
  linkEmailSuccessCallback: null,
  headerSearchMaskOpen: false,
  pendingDrawerOpen: false,
  pendingUserOperation: null,
  unreadCount: 0,
  myConnectionTab: 0,
  homepageCollapseStatus: {
    individual: true,
    organization: true,
    post: true,
    event: true,
  },
  homeTaskAutoplay: true,
};
if (typeof window !== 'undefined') {
  initialState.accessToken = window.localStorage.getItem(LS_ACCESS_TOKEN_KEY);
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    save: (state, action) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
    updateAccessToken: (state, action: { payload: { value: string } }) => {
      const { value } = action.payload;

      //TODO: 这里最好放纯函数
      (window as any).localStorage[LS_ACCESS_TOKEN_KEY] = value;
      state.accessToken = value;
    },
    deleteAccessToken: (state) => {
      state.accessToken = null;
      delete (window as any).localStorage[LS_ACCESS_TOKEN_KEY];
    },
    toggleGlobalLinkTwitterModalStatus: (state, action: { payload: TGlobalTwitterModalStatus }) => {
      state.globalLinkTwitterModalStatus = action.payload;
    },
    setNeedRefetchEvents: (
      state,
      action: {
        payload: {
          landing?: boolean;
          explore?: boolean;
        };
      },
    ) => {
      state.needRefetchEvents = {
        ...state.needRefetchEvents,
        ...action.payload,
      };
    },
    toggleIsTopUpOpen(state, action: { payload: boolean }) {
      state.isTopUpOpen = action.payload;
    },
    setSignModalStatus(
      state,
      action: {
        payload: {
          isOpen: boolean;
          sign?: () => Promise<void>;
        };
      },
    ) {
      state.signModalStatus = {
        isOpen: action.payload.isOpen,
        sign: action.payload.sign,
      };
    },
    toggleIsDepositModalOpen(state, action: { payload: boolean }) {
      state.isDepositModalOpen = action.payload;
    },
    toggleIsWithdrawModalOpen(state, action: { payload: boolean }) {
      state.isWithdrawModalOpen = action.payload;
    },
    setGlobalTheme(
      state,
      action: {
        payload: 'light' | 'dark';
      },
    ) {
      state.theme = action.payload;
    },

    startPollingCredit(state) {
      state.creditPollingInterval = 3000;
    },
    stopPollingCredit(state) {
      state.creditPollingInterval = 0;
    },
    toggleLinkEmailModalOpen(state, action: { payload: boolean }) {
      state.linkEmailModalOpen = action.payload;
    },
    setLinkEmailSuccessCallback(state, action: { payload: () => void }) {
      state.linkEmailSuccessCallback = action.payload;
    },
    toggleHeaderSearchMaskOpen(state, action: { payload: boolean }) {
      state.headerSearchMaskOpen = action.payload;
    },
    togglePendingDrawerOpen(
      state,
      action: { payload: { open: boolean; pendingUserOperation: PendingUserOperation | null } },
    ) {
      state.pendingDrawerOpen = action.payload.open;
      state.pendingUserOperation = action.payload.pendingUserOperation;
    },
    setUnreadCount(state, action: { payload: number }) {
      state.unreadCount = action.payload;
    },
    setMyConnectionTab(state, action: { payload: 0 | 1 | 2 }) {
      state.myConnectionTab = action.payload;
    },

    setHomepageCollapseStatus(
      state,
      action: {
        payload: THomepageCollapseStatus;
      },
    ) {
      state.homepageCollapseStatus = {
        ...state.homepageCollapseStatus,
        ...action.payload,
      };
    },
    toggleHomeTaskAutoplay(state, action: { payload: boolean }) {
      state.homeTaskAutoplay = action.payload;
    },
  },
});

export const {
  save,
  updateAccessToken,
  deleteAccessToken,
  toggleGlobalLinkTwitterModalStatus,
  setNeedRefetchEvents,
  toggleIsTopUpOpen,
  toggleIsDepositModalOpen,
  setSignModalStatus,
  setGlobalTheme,
  startPollingCredit,
  stopPollingCredit,
  toggleLinkEmailModalOpen,
  setLinkEmailSuccessCallback,
  toggleHeaderSearchMaskOpen,
  togglePendingDrawerOpen,
  setUnreadCount,
  setMyConnectionTab,
  setHomepageCollapseStatus,
  toggleIsWithdrawModalOpen,
  toggleHomeTaskAutoplay,
} = globalSlice.actions;

export default globalSlice.reducer;
