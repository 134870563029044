import { useAuth } from '@/hooks';
import useIsStandAlone from '@/hooks/useIsStandAlone';
import { deleteFcmToken, getDeviceId, getFcmToken, setFcmToken } from '@/utils';
import firebaseApp, { vapid } from '@/utils/firebase';
import { useMeQuery, useRegisterFcmTokenMutation, useUnregisterFcmTokenMutation } from '@cyber/service/pwa';
import { getMessaging, getToken } from 'firebase/messaging';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import NotificationModal from '../NotificationModal';

type NotificationContextProps = {
  retrieveToken: () => Promise<string | boolean>;
  unregisterToken: () => Promise<void>;
};

type Link3Notification = {
  type: 'dm';
  data: {
    chatId?: string;
  };
};

const NotificationContext = createContext<NotificationContextProps>({
  retrieveToken: async () => false,
  unregisterToken: async () => {},
});

export default function NotificationProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { isLoggedIn } = useAuth();
  const isStandalone = useIsStandAlone();

  const { data } = useMeQuery(undefined, { skip: !isLoggedIn });
  const userId = data?.me?.data?.id as string;

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  const [registerFcmTokenCaller] = useRegisterFcmTokenMutation();
  const [unregisterFcmToken] = useUnregisterFcmTokenMutation();

  const retrieveToken = async () => {
    return new Promise<string | boolean>((resolve, reject) => {
      try {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
          const messaging = getMessaging(firebaseApp);
          Notification.requestPermission(async (permission) => {
            if (permission === 'granted' && messaging) {
              const currentToken = await getToken(messaging, {
                vapidKey: vapid,
              });
              if (currentToken) {
                await registerFcmTokenCaller({ deviceId: getDeviceId(), token: currentToken });
                resolve(currentToken);
              } else {
                reject(false);
                console.log('No registration token available. Request permission to generate one.');
              }
            } else {
              reject(false);
            }
          });
        }
      } catch (error) {
        reject(false);
        console.log('An error occurred while retrieving token:', error);
      }
    });
  };

  const unregisterToken = async () => {
    await unregisterFcmToken({ deviceId: getDeviceId() });
    deleteFcmToken(userId);
  };

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        // TOFIX: 在app没有开启的时候，会跳不到message页面
        const data: Link3Notification = event.data;
        if (data.data.chatId) {
          router.push(`/message/${data.data.chatId}`);
        }
      });
    }
  }, [router]);

  useEffect(() => {
    const handleNotificationPermission = async () => {
      if (userId && isStandalone && isLoggedIn && typeof window !== 'undefined' && 'Notification' in window) {
        if (Notification.permission === 'default') {
          setNotificationModalOpen(true);
        }
        if (Notification.permission === 'granted') {
          if (getFcmToken(userId)) {
            return;
          } else {
            try {
              const fcmToken = await retrieveToken();
              if (typeof fcmToken === 'string') {
                setFcmToken(userId, fcmToken);
              }
            } catch (e) {
              return false;
            }
          }
        }
      }
    };

    handleNotificationPermission();
  }, [isLoggedIn, userId, isStandalone]);

  return (
    <NotificationContext.Provider value={{ retrieveToken, unregisterToken }}>
      {children}
      <NotificationModal open={notificationModalOpen} onClose={() => setNotificationModalOpen(false)} />
    </NotificationContext.Provider>
  );
}

export const useNotification = () => useContext(NotificationContext);
