import { actions } from '@/store/reducers';
import { TRootState } from '@/store/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useDepositModal() {
  const isOpen = useSelector((state: TRootState) => state.global.isDepositModalOpen);
  const dispatch = useDispatch();

  const toggleModal = useCallback(
    (open = !isOpen) => {
      dispatch(actions.global.toggleIsDepositModalOpen(open));
    },
    [dispatch, isOpen],
  );

  return {
    isOpen,
    toggleModal,
  };
}

export default useDepositModal;
