import { MessageListItemFragment } from '@cyber/service/pwa';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  payLoading: boolean;
  isRefreshingPrice: boolean;
  messageList: MessageListItemFragment[];
  shouldRefetch: boolean;
} = { payLoading: false, isRefreshingPrice: false, messageList: [], shouldRefetch: false };

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setPayLoading(state, action: { payload: boolean }) {
      state.payLoading = action.payload;
    },
    setIsRefreshingPrice(state, action: { payload: boolean }) {
      state.isRefreshingPrice = action.payload;
    },
    setMessageList(state, action: { payload: MessageListItemFragment[] }) {
      state.messageList = action.payload;
    },
    deleteFromMessageList(state, action: { payload: string }) {
      state.messageList = state.messageList.filter((item) => item.id !== action.payload);
      state.shouldRefetch = true;
    },
    setShouldRefetch(state, action: { payload: boolean }) {
      state.shouldRefetch = false;
    },
  },
});

export const { setPayLoading, setIsRefreshingPrice, setMessageList, deleteFromMessageList, setShouldRefetch } =
  messageSlice.actions;

export default messageSlice.reducer;
