import { useMeQuery } from '@cyber/service/pwa';
import React from 'react';
import { formatUnits } from 'viem';
import { useAuth } from './';

const useGasCredit = () => {
  const [gasCredit, setGasCredit] = React.useState(0);
  const [gasLocked, setGasLocked] = React.useState('');
  const [pollingInterval, setPollingInterval] = React.useState(0);
  const { isLoggedIn } = useAuth();
  const [isFetchingGasCredit, setIsFetchingGasCredit] = React.useState(false);
  const { balance, pending, locked, decimals, refetch } = useMeQuery(undefined, {
    skip: !isLoggedIn,
    selectFromResult({ currentData }) {
      return {
        balance: Number(currentData?.me.data?.v3Info?.credits?.[0]?.balance || 0),
        pending: Number(currentData?.me.data?.v3Info?.credits?.[0]?.pending || 0),
        locked: Number(currentData?.me.data?.v3Info?.credits?.[0]?.locked || 0),
        decimals: Number(currentData?.me.data?.v3Info?.credits?.[0]?.decimals || 0),
      };
    },
    pollingInterval,
  });

  React.useEffect(() => {
    if (pending > 0) {
      setPollingInterval(0);
      setIsFetchingGasCredit(false);
    }
  }, [pending]);

  React.useEffect(() => {
    try {
      const base = balance + pending - locked;

      // console.log('balance', balance);

      const newGasCredit = Number((Number(base) / Math.pow(10, decimals)).toFixed(2));

      if (gasCredit !== newGasCredit) {
        setGasCredit(newGasCredit);
      }
      setGasLocked(formatUnits(BigInt(locked), decimals));
    } catch (e) {
      console.log('Error in SmartGas calculation', e);
    }
  }, [balance, pending, locked, gasCredit]);

  React.useEffect(() => {
    if (pollingInterval > 0) {
      refetch();
      setIsFetchingGasCredit(true);
    }
  }, [pollingInterval]);

  return { gasCredit, gasLocked, setGasCreditPollingInterval: setPollingInterval, isFetchingGasCredit, refetch };
};

export default useGasCredit;
