import { Icon } from '@link3-pwa/icons';
import { Stack, StackProps, styled, SxProps } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { CopyToClipboard, Props } from 'react-copy-to-clipboard';
import Flex, { FlexProps } from '../Flex';
import Text from '../Text';

type TClipboardProps = {
  sx?: SxProps;
  prefix?: ReactNode;
  wrapperProps?: FlexProps;
  bodyProps?: StackProps;
  text: ReactNode | string;
  children?: ReactNode;
  resetTime?: number;
  iconProps?: any;
  underline?: boolean;
} & Pick<Props, 'text'>;

const StyledClipboard = styled(Flex)`
  align-items: center;
  width: fit-content;
  max-width: 100%;
  cursor: pointer;
`;

function Clipboard(props: TClipboardProps) {
  const {
    resetTime = 5000,
    text,
    prefix,
    children,
    wrapperProps,
    bodyProps,
    iconProps,
    underline = true,
    ...restClipProps
  } = props;
  const [isCopied, toggleIsCopied] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isCopied) {
      timer = setTimeout(() => {
        toggleIsCopied(false);
      }, resetTime);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isCopied]);

  return (
    <StyledClipboard {...wrapperProps}>
      <Stack flexDirection='row' gap={1} alignItems='center' width='100%' {...bodyProps}>
        {prefix && prefix}
        {children || (
          <Text
            onClick={() => {
              if (text.startsWith('http')) window.open(text, '_blank');
            }}
            sx={{
              textDecoration: underline ? 'underline' : 'none',
            }}
          >
            {text}
          </Text>
        )}
        <CopyToClipboard text={text} onCopy={() => toggleIsCopied(true)} {...restClipProps}>
          {isCopied ? (
            <Icon size={16} name='icon-check-line' color='success.main' {...iconProps} />
          ) : (
            <Icon name='icon-file-copy-line' size={16} sx={{ cursor: 'pointer' }} {...iconProps} />
          )}
        </CopyToClipboard>
      </Stack>
    </StyledClipboard>
  );
}

export default Clipboard;
