import { Box, BoxProps } from '@mui/material';
import { Icon } from '@link3-pwa/icons';

interface IRadioProps extends BoxProps {
  checked?: boolean;
  color?: string;
  iconName?: string;
  needBackground?: string;
}

function Radio(props: IRadioProps) {
  const { checked, color = 'text.100', iconName, ...restProps } = props;
  return (
    <Box
      position='relative'
      borderRadius={100}
      display='flex'
      alignItems='center'
      justifyContent='center'
      {...restProps}
    >
      {checked ? (
        <Icon name={iconName || 'icon-chosen-color-fill'} color='brand.main' />
      ) : (
        <Icon name={'icon-checkbox-blank-circle-line'} color={color} />
      )}
    </Box>
  );
}

export default Radio;
