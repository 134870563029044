import { Box, styled, SxProps } from '@mui/material';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import AdvancedCropper, { FixedCropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

const CropperWrapper = styled(Box)`
  width: 100%;
  height: 100%;

  .banddit-fixed-cropper {
    .advanced-cropper-stencil-overlay {
      border: 1px solid #f4f5f6;
      /* border-radius: 8px; */

      &.circle {
        border-radius: 50%;
      }
    }
  }
`;

export type FixedCropperProps = Omit<AdvancedCropper.FixedCropperProps, 'stencilSize'> & {
  stencilType?: 'circle' | 'rectangle';
};

export type FixedCropperRef = AdvancedCropper.FixedCropperRef | null;

const Cropper: ForwardRefRenderFunction<FixedCropperRef, FixedCropperProps & { sx?: SxProps; ratio?: number }> = (
  props,
  ref,
) => {
  const { sx, stencilType, ratio = 1, ...restProps } = props;

  const isCircle = stencilType === 'circle';

  return (
    <CropperWrapper sx={sx}>
      <FixedCropper
        className='banddit-fixed-cropper'
        stencilSize={({ boundary }) => {
          return {
            width: isCircle ? boundary.height : boundary.width,
            height: isCircle ? boundary.height : boundary.height * ratio,
          };
        }}
        defaultSize={({ imageSize, visibleArea }) => {
          return {
            width: (visibleArea || imageSize).width,
            height: (visibleArea || imageSize).height,
          };
        }}
        ref={ref}
        style={{ width: '100%', height: '100%' }}
        // cropper options
        canvas
        {...restProps}
        //   options cannot be override
        stencilProps={{
          previewClassName: isCircle ? 'circle' : '',
          overlayClassName: isCircle ? 'circle' : '',
          handlers: {},
          lines: {},
          movable: true,
          resizable: false,
        }}
      />
    </CropperWrapper>
  );
};

export default forwardRef(Cropper);
