import { Box, Stack, Text } from '@cyberlab/uikit';
import Image from 'next/image';
import { TabType } from './Tab';

const CONTENT: {
  [key: string]: {
    title: JSX.Element;
    image: { src: string; width: number; height: number };
  }[];
} = {
  ios: [
    {
      title: (
        <Text bold>
          1. In{' '}
          <Text display='inline' color='brand.main' bold>
            Safari
          </Text>
          , tap the share icon (bottom middle)
        </Text>
      ),
      image: {
        src: '/assets/install/ios-install-1.png',
        width: 254,
        height: 80,
      },
    },
    {
      title: <Text bold>2. Tap “Add to Home Screen”</Text>,
      image: {
        src: '/assets/install/ios-install-2.png',
        width: 254,
        height: 80,
      },
    },
  ],
  android: [
    {
      title: (
        <Text bold>
          1. In{' '}
          <Text display='inline' color='brand.main' bold>
            Chrome
          </Text>
          , tap the 3 dot icon (upper right)
        </Text>
      ),
      image: {
        src: '/assets/install/android-install-1.png',
        width: 254,
        height: 50,
      },
    },
    {
      title: <Text bold>2. Tap "Install app"</Text>,
      image: {
        src: '/assets/install/android-install-2.png',
        width: 254,
        height: 64,
      },
    },
  ],
};

function Content({ type }: { type: TabType }) {
  const content = CONTENT[type];
  return (
    <Stack maxWidth={260} pt={1} gap={4}>
      {content.map((c) => (
        <Stack gap={1.5}>
          <Box>{c.title}</Box>

          <Image {...c.image} />
        </Stack>
      ))}
    </Stack>
  );
}

export default Content;
