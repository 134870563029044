export enum DiscordOauth {
  DISCORD_LINK = 'DISCORD-LINK',
  DISCORD_BOT = 'DISCORD-BOT',
  DISCORD_BOT_UPDATE = 'DISCORD-BOT-UPDATE',
}

export enum RedirectFromType {
  Me = 'Me',
  Profile = 'Profile',
}
