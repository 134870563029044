import { loadingButtonClasses } from '@mui/lab';
import { buttonClasses } from '@mui/material';
import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme, alpha } from '@mui/material/styles';
declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    tiny: true;
  }
}
const loadingClass = `&.${loadingButtonClasses.loading}`;
const loadingIndicatorStartClass = ` .${loadingButtonClasses.loadingIndicatorStart}`;
const loadingIndicatorClass = ` .${loadingButtonClasses.loadingIndicator}`;
export const buttonStyle: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    // disableTouchRipple: true,
    size: 'small',
  },
  variants: [
    {
      props: { size: 'tiny' },
      style: ({ theme }) => ({
        height: 28,
        padding: '0 12px',
        fontSize: 12,
      }),
    },
    {
      props: { size: 'small' },
      style: ({ theme }) => ({
        height: 36,
        padding: '0 16px',
        fontSize: 14,
      }),
    },
    {
      props: { size: 'medium' },
      style: ({ theme }) => ({
        height: 42,
        fontSize: 16,
        padding: '0 20px',
      }),
    },
    {
      props: { size: 'large' },
      style: ({ theme }) => ({
        height: 48,
        padding: '0 24px',
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme }) => {
      return {
        borderRadius: theme.shape.borderRadius,
        fontWeight: 700,
        fontSize: 16,
        paddingTop: 0,
        paddingBottom: 0,
        minWidth: 0,
        [loadingIndicatorClass]: {
          color: theme.palette.text.primary,
        },
        [loadingIndicatorStartClass]: {
          position: 'static',
          marginRight: theme.spacing(0.5),
        },
        transition: 'all .3s ease',
      };
    },
    startIcon: {
      marginRight: 4,
    },
    // disable must be placed before other styles
    containedPrimary: ({ theme }) => ({
      ':disabled': {
        backgroundColor: theme.palette.primary.disabled,
        color: theme.palette.text[250],
      },
      [loadingClass]: {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
      },
      [loadingIndicatorClass]: {
        color: theme.palette.common.black,
      },
    }),
    containedSecondary: ({ theme }) => ({
      [loadingClass]: {
        color: 'inherit',
      },
      [loadingIndicatorClass]: {
        color: 'inherit',
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      borderColor: theme.palette.secondary.main,
      ':disabled': {
        color: theme.palette.primary.disabled,
        borderColor: theme.palette.primary.disabled,
      },
      [loadingClass]: {
        color: theme.palette.common.white,
      },
      [loadingIndicatorClass]: {
        color: theme.palette.common.white,
      },
      background: 'transparent',
    }),
    text: {},
    // textPrimary: ({ theme }) => {},
  },
};
